import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./Collections.scss"
import Collections from './Collections';
import h1 from "../../../Images/Collections/9. Horses/1.jpg"
import h2 from "../../../Images/Collections/9. Horses/2.jpg"
import h3 from "../../../Images/Collections/9. Horses/3.jpg"
import h4 from "../../../Images/Collections/9. Horses/4.jpg"
import h5 from "../../../Images/Collections/9. Horses/5.jpg"
import h6 from "../../../Images/Collections/9. Horses/6.jpg"
import h7 from "../../../Images/Collections/9. Horses/7.jpg"
import h8 from "../../../Images/Collections/9. Horses/8.jpg"
import h9 from "../../../Images/Collections/9. Horses/9.jpg"
import h10 from "../../../Images/Collections/9. Horses/10.jpg"
import h11 from "../../../Images/Collections/9. Horses/11.jpg"
import h12 from "../../../Images/Collections/9. Horses/12.jpg"
import h13 from "../../../Images/Collections/9. Horses/13.jpg"
import h14 from "../../../Images/Collections/9. Horses/14.jpg"
import h15 from "../../../Images/Collections/9. Horses/15.jpg"
import h16 from "../../../Images/Collections/9. Horses/16.jpg"

const Horses = () => {

        const images = [
                {
                        original: h1,
                       thumbnail: h1,
                },
                {
                        original: h2,
                       thumbnail: h2,
                },
                {
                        original: h3,
                       thumbnail: h3,
                },
                {
                        original: h4,
                       thumbnail: h4,
                },
                {
                        original: h5,
                       thumbnail: h5,
                },
                {
                        original: h6,
                       thumbnail: h6,
                },
                {
                        original: h7,
                       thumbnail: h7,
                },
                {
                        original: h8,
                       thumbnail: h8,
                },
                {
                        original: h9,
                       thumbnail: h9,
                },
                {
                        original: h10,
                       thumbnail: h10,
                },
                {
                        original: h11,
                       thumbnail: h11,
                },
                {
                        original: h12,
                       thumbnail: h12,
                },
                {
                        original: h13,
                       thumbnail: h13,
                },
                {
                        original: h14,
                       thumbnail: h14,
                },
                {
                        original: h15,
                       thumbnail: h15,
                },
                {
                        original: h16,
                       thumbnail: h16,
                },
        ]

        return (
                <>
                        <Collections
                                link="/horses"
                                linkname="Horses"
                                img={images}
                                slider_headline="Horses"
                                slider_text="The Horses collection embodies the
                            spirit of these noble animals,
                            celebrating their strength, grace, and
                            beauty through stunning artistic
                            expression; making it a perfect choice
                            for those who appreciate the elegance
                            and power of these magnificent
                            creatures."
                                p1="Immerse yourself in the elegance and power of our Horses collection, where each painting captures the noble spirit and dynamic grace of these magnificent creatures. From galloping stallions to serene pastures, these artworks reflect the timeless bond between humans and horses, brought to life through
                        exquisite detail and rich hues."
                                p2="Our artists meticulously portray the strength, beauty, and freedom of horses, creating pieces that
                        resonate with both art lovers and equestrian enthusiasts alike. The fluid motion and expressive forms in
                        each painting make them a striking addition to any setting, whether it be a home, office, or gallery."
                                p3="Celebrate the majestic allure of horses and elevate your space with the captivating charm of our Horses
                        collection, where every piece tells a story of elegance and power."
                                p4=""
                                url="https://youtu.be/DJqvCpm8L4M?si=QEfXm9bLVOc9ARqh"
                                videoHeadline="Mastering the Brush: Creating Art by Hand"
                                videotext="Explore our extensive collection and behold its eclectic array. With thousands of choices available,
                        finding the perfect piece is effortless. Need assistance in making a decision? Our knowledgeable
                        team is on hand to provide expert advice and help you curate the perfect selection for your space."
                        />
                </>
        )
}

export default Horses