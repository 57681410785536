// components =============================
import React, { useState } from 'react'

// components ends ========================

// scss =============================
import "./Header.scss"
import { Link } from 'react-router-dom'
// scss ends ========================

// images =============================
import logoimg from "../../Images/Logo/logo.png"
import logotext from "../../Images/Logo/logo text.png"
import instagram from "../../Images/Icons/instagram.jpg"
import facebook from "../../Images/Icons/facebook.jpg"
import youtube from "../../Images/Icons/youtube.jpg"
import twitter from "../../Images/Icons/twitter.jpg"

// images ends ========================

const Header = () => {

  const [showMenu, setShowMenu] = useState("none")

  const show = () => {
    setShowMenu("flex");
  }

  const hide = () => {
    setShowMenu("none");
  }

  const [MegaMenu, setMegaMenu] = useState("");

  const showMegaMenu = () => {
    setMegaMenu("flex");
  }


  const [MegaMenumobile, setMegaMenumobile] = useState("");

  const showMegaMenumobile = () => {
    setMegaMenumobile("flex");
  }

  return (
    <>
      <div className="header">

        <div className="header-info">
          <p>Get 10% Discount On Your First Order</p>
        </div>
        <div className="header-links">
          <div className="links1">
            {/* {links.slice(0, 3).map((item) => (
              <Link to="/">Home</Link>
            ))} */}
            <Link to="/">Home</Link>
            <Link to="/#about">About</Link>
            <Link className='pro' onClick={showMegaMenu}>Products</Link>
            <div className="pro-link" style={{ display: MegaMenu }}>
              <div className="link1">
                <h3>Collections</h3>
                <div className="link-body">
                  <div className='links'>
                    <Link to={'/royal-portraits'} >&#10158; &nbsp;royal portraits</Link>
                    <Link to={'/uae-heritage'} >&#10158; &nbsp;uae heritage</Link>
                    <Link to={'/scenery'} >&#10158; &nbsp;scenery & landscapes</Link>
                    <Link to={'/al-arab'} >&#10158; &nbsp;al arab</Link>
                    <Link to={'/middle-eastern'} >&#10158; &nbsp;middle eastern</Link>
                    <Link to={'/renaissance'} >&#10158; &nbsp;renaissance</Link>
                  </div>
                  <div className='links'>
                    <Link to={'/classical-indian'} >&#10158; &nbsp;classical indian</Link>
                    <Link to={'/islamic-art'} >&#10158; &nbsp;islamic art</Link>
                    <Link to={'/horses'} >&#10158; &nbsp;horses</Link>
                    <Link to={'/animals'} >&#10158; &nbsp;animals</Link>
                    <Link to={'/flowers'} >&#10158; &nbsp;flowers</Link>
                    <Link to={'/gautam-buddha'} >&#10158; &nbsp;gautam buddha</Link>
                  </div>
                </div>


              </div>

              <div className="link2">
                <h3>Frames</h3>
                <div className="link-body">
                  <div className="links">
                    <Link to="/frames" onClick={() => { setMegaMenu("none") }}>&#10158; &nbsp;stretched canvas wrap</Link>
                    <Link to="/frames" onClick={() => { setMegaMenu("none") }}>&#10158; &nbsp;baroque wodden luxury</Link>
                    <Link to="/frames" onClick={() => { setMegaMenu("none") }}>&#10158; &nbsp;empyreal metal</Link>
                    <Link to="/frames" onClick={() => { setMegaMenu("none") }}>&#10158; &nbsp;renaissance vintage</Link>
                  </div>

                </div>


              </div>
              <div className="link3">
                <h3><Link className='last-link' to="/customized-paintings">Customized Paintings</Link></h3>
              </div>
            </div>
          </div>

          <div className="header-logo">
            <img src={logoimg} alt="logo" className='logoimg' />
            <img src={logotext} alt="logo" className='logotext' />
          </div>

          <div className="links2">
            {/* {links.slice(3, 4).map((item) => (
              <Link to="/">Home</Link>
            ))} */}
            <Link to="/contact">Contact Us</Link>
          </div>

        </div>

        <div className="header-mobile">
          <div className="header-logo">
            <img src={logoimg} alt="logo" className='logoimg' />
            <img src={logotext} alt="logo" className='logotext' />
          </div>
          <svg onClick={show} className='menu' xmlns="http://www.w3.org/2000/svg" id="Bold" viewBox="0 0 24 24" width="512" height="512"><path d="M8,7H22.5a1.5,1.5,0,0,0,0-3H8A1.5,1.5,0,0,0,8,7Z" /><path d="M22.5,11H8a1.5,1.5,0,0,0,0,3H22.5a1.5,1.5,0,0,0,0-3Z" /><path d="M22.5,18H8a1.5,1.5,0,0,0,0,3H22.5a1.5,1.5,0,0,0,0-3Z" /><circle cx="2.5" cy="5.5" r="2.5" /><circle cx="2.5" cy="12" r="2.5" /><circle cx="2.5" cy="19" r="2.5" /></svg>

          <div className="links1" style={{ display: showMenu }}>
            <svg onClick={hide} className='close' id="Layer_1" enable-background="new 0 0 100 100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><path d="m96.2581024 76.5555725-26.5555801-26.5555725 26.5555801-26.5555763c1.6558609-1.6558666 1.6558609-4.3405552 0-5.9964199l-13.7061081-13.7061045c-1.6558609-1.6558657-4.3405533-1.6558657-5.9964218 0l-26.5555725 26.5555765-26.5555763-26.5555765c-1.6558647-1.6558657-4.3405533-1.6558657-5.9964199 0l-13.7061036 13.7061045c-1.6558657 1.6558647-1.6558657 4.3405533 0 5.9964199l26.5555756 26.5555763-26.5555756 26.5555725c-1.6558657 1.6558685-1.6558657 4.3405609 0 5.9964218l13.7061033 13.7061081c1.6558666 1.6558609 4.3405552 1.6558609 5.9964199 0l26.5555766-26.5555801 26.5555725 26.5555801c1.6558685 1.6558609 4.3405609 1.6558609 5.9964218 0l13.7061081-13.7061081c1.6558609-1.6558609 1.6558609-4.3405533 0-5.9964218z" /></svg>
            <Link className='first' to="/">Home</Link>
            <Link to="/#about">About</Link>
            <Link onClick={showMegaMenumobile}>Products</Link>
            <div className="pro-link" style={{ display: MegaMenumobile }}>
            <svg onClick={() => { setMegaMenumobile("none") }} className='close' id="Layer_1" enable-background="new 0 0 100 100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><path d="m96.2581024 76.5555725-26.5555801-26.5555725 26.5555801-26.5555763c1.6558609-1.6558666 1.6558609-4.3405552 0-5.9964199l-13.7061081-13.7061045c-1.6558609-1.6558657-4.3405533-1.6558657-5.9964218 0l-26.5555725 26.5555765-26.5555763-26.5555765c-1.6558647-1.6558657-4.3405533-1.6558657-5.9964199 0l-13.7061036 13.7061045c-1.6558657 1.6558647-1.6558657 4.3405533 0 5.9964199l26.5555756 26.5555763-26.5555756 26.5555725c-1.6558657 1.6558685-1.6558657 4.3405609 0 5.9964218l13.7061033 13.7061081c1.6558666 1.6558609 4.3405552 1.6558609 5.9964199 0l26.5555766-26.5555801 26.5555725 26.5555801c1.6558685 1.6558609 4.3405609 1.6558609 5.9964218 0l13.7061081-13.7061081c1.6558609-1.6558609 1.6558609-4.3405533 0-5.9964218z" /></svg>

              <div className="link1">
                <h3>Collections</h3>
                <div className="link-body">
                  <div className='links'>
                    <Link to={'/royal-portraits'} >&#10158; &nbsp;royal portraits</Link>
                    <Link to={'/uae-heritage'} >&#10158; &nbsp;uae heritage</Link>
                    <Link to={'/scenery'} >&#10158; &nbsp;scenery & landscapes</Link>
                    <Link to={'/al-arab'} >&#10158; &nbsp;al arab</Link>
                    <Link to={'/middle-eastern'} >&#10158; &nbsp;middle eastern</Link>
                    <Link to={'/renaissance'} >&#10158; &nbsp;renaissance</Link>
                  </div>
                  <div className='links'>
                    <Link to={'/classical-indian'} >&#10158; &nbsp;classical indian</Link>
                    <Link to={'/islamic-art'} >&#10158; &nbsp;islamic art</Link>
                    <Link to={'/horses'} >&#10158; &nbsp;horses</Link>
                    <Link to={'/animals'} >&#10158; &nbsp;animals</Link>
                    <Link to={'/flowers'} >&#10158; &nbsp;flowers</Link>
                    <Link to={'/gautam-buddha'} >&#10158; &nbsp;gautam buddha</Link>
                  </div>
                </div>


              </div>

              <div className="link2">
                <h3>Frames</h3>
                <div className="link-body">
                  <div className="links">
                    <Link to="/frames" onClick={() => { setMegaMenu("none") }}>&#10158; &nbsp;stretched canvas wrap</Link>
                    <Link to="/frames" onClick={() => { setMegaMenu("none") }}>&#10158; &nbsp;baroque wodden luxury</Link>
                    <Link to="/frames" onClick={() => { setMegaMenu("none") }}>&#10158; &nbsp;empyreal metal</Link>
                    <Link to="/frames" onClick={() => { setMegaMenu("none") }}>&#10158; &nbsp;renaissance vintage</Link>
                  </div>

                </div>


              </div>
              <div className="link3">
                <h3><Link className='last-link' to="/customized-paintings">Customized Paintings</Link></h3>
              </div>
            </div>
            <Link to="/contact">Contact</Link>
          </div>
        </div>

      </div>
    </>
  )
}

export default Header