import React, { useState, useEffect, useRef } from 'react'
import Header from '../../Molecules/Header/Header'
import Whatsapp from '../../Molecules/Whatsapp/Whatsapp'
import Footer from '../../Molecules/Footer/Footer'
import Title from '../../Molecules/Title/Title'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import fb from "../../Firebase/firebase";
const DB = fb.firestore()
const GITF = DB.collection('Contact_form');

const Contact = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, []);


    const form = useRef();
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Message: ""
    });
    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }
    //connect with firebase

    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Mobile, Message, } = userData;
        if (Name && Email && Mobile && Message) {

            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                toast.warn('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Mobile)) {
                    toast.warn("Please enter only number.");
                    return

                }
                //  else if (userData.Mobile.length !== 10) {
                //     alert("Please enter 10 digit phone number.");
                //     return
                // }
            }

            try {

                GITF.add({
                    Name: Name,
                    Email: Email,
                    Mobile: Mobile,
                    Message: Message
                }).then((docRef) => {
                    console.log(docRef);
                    toast.success("Message Sent Successfully");
                }).catch((error) => {
                    console.error("error:", error);
                });

                emailjs.sendForm('service_af2d1jc', 'template_pdgczna', form.current, 'eu-BX4X4PMvM5q_e0')
                    .then((result) => {
                        console.log(result.text);

                    }, (error) => {
                        console.log(error.text);
                    });

                setUserData({
                    Name: "",
                    Email: "",
                    Mobile: "",
                    Message: ""
                })
                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                toast.warn("Error adding document")
            }
        }
        else {
            toast.warn('Please fill the data');
        }
    };

    return (
        <>
            <Header />
            <Whatsapp />
            <ToastContainer />
            <div className="contact-page">
                <Title title="Contact Us" />
                <div className="frame8"  >
                    <form className="form" ref={form} method="POST" >
                        <h3>Send us a message</h3>
                        <p>Please fill in the form and we will get back to you as soon as possible.</p>
                        {/* <form ref={form} method="POST" > */}
                        <div className="input" >
                            <input
                                type="text"
                                placeholder='Full Name'
                                name="Name"
                                value={userData.Name}
                                onChange={postUserData}
                            />
                            <input type="text"
                                placeholder='phone number'
                                name="Mobile"
                                value={userData.Mobile}
                                onChange={postUserData}
                            />
                        </div>

                        <input type="email"
                            placeholder='email address'
                            id='contact'
                            name="Email"
                            value={userData.Email}
                            onChange={postUserData}
                        />
                        <textarea
                            placeholder='message'
                            name="Message"
                            value={userData.Message}
                            onChange={postUserData}
                        ></textarea>

                        <button onClick={SubmitData}>Send Message</button>
                    </form>
                    {/* </div> */}
                    <div className="frame8-info" >
                        {/* <div className="frame8-info-card">
                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,0A10.011,10.011,0,0,0,2,10c0,5.282,8.4,12.533,9.354,13.343l.646.546.646-.546C13.6,22.533,22,15.282,22,10A10.011,10.011,0,0,0,12,0Zm0,15a5,5,0,1,1,5-5A5.006,5.006,0,0,1,12,15Z" /><circle cx="12" cy="10" r="3" /></svg>
                        <h4>Address</h4>
                        <p>Abc street, building xyz,
                            alpha street, Dubai</p>
                    </div> */}
                        <div className="frame8-info-card">
                            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23,11a1,1,0,0,1-1-1,8.008,8.008,0,0,0-8-8,1,1,0,0,1,0-2A10.011,10.011,0,0,1,24,10,1,1,0,0,1,23,11Zm-3-1a6,6,0,0,0-6-6,1,1,0,1,0,0,2,4,4,0,0,1,4,4,1,1,0,0,0,2,0Zm2.183,12.164.91-1.049a3.1,3.1,0,0,0,0-4.377c-.031-.031-2.437-1.882-2.437-1.882a3.1,3.1,0,0,0-4.281.006l-1.906,1.606A12.784,12.784,0,0,1,7.537,9.524l1.6-1.9a3.1,3.1,0,0,0,.007-4.282S7.291.939,7.26.908A3.082,3.082,0,0,0,2.934.862l-1.15,1C-5.01,9.744,9.62,24.261,17.762,24A6.155,6.155,0,0,0,22.183,22.164Z" /></svg>
                            <h4>Phone</h4>
                            <p>+971 50 434 8145</p>

                        </div>
                        <div className="frame8-info-card">
                            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M1.2,8.437,8.48,1.449a5,5,0,0,1,7.055.015l7.27,6.973-8.684,8.685a3.074,3.074,0,0,1-4.242,0Zm14.34,10.1a5,5,0,0,1-7.07,0L.16,10.23A3.962,3.962,0,0,0,0,11.28V24H24V11.28a3.962,3.962,0,0,0-.16-1.05Z" /></svg>
                            <h4>Email</h4>
                            <p>
                                {/* <b>General Enquiry</b> <br /> */}
                                info@sketchostorypaintings.com
                            </p>
                            {/* <p>
                                <b>To Order</b> <br />
                                sales@sketchostorypaintings.com
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Contact