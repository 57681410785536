import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/storage'

firebase.initializeApp({
    apiKey: "AIzaSyA8M2_bwphKI2HGYptETPQZNDigwFoz-aM",
    authDomain: "sketchostorypaintings-da179.firebaseapp.com",
    projectId: "sketchostorypaintings-da179",
    storageBucket: "sketchostorypaintings-da179.appspot.com",
    messagingSenderId: "81032668582",
    appId: "1:81032668582:web:f4284eb23e0073d9095b67",
    measurementId: "G-5MXESNNHC3"
});

const fb = firebase;

export default fb;