import React, { useEffect, useState, useRef } from 'react'
import Header from '../../../Molecules/Header/Header'
import Footer from '../../../Molecules/Footer/Footer'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./Collections.scss"
import sizechart1 from "../../../Images/Customized Paintings/size chart.png"
import sizechart2 from "../../../Images/Customized Paintings/Picture4 (1).png"
import ReactImageZoom from 'react-image-zoom';
import al1 from "../../../Images/Collections/4. Al Arab/1.jpg"
import Whatsapp from "../../../Molecules/Whatsapp/Whatsapp"
import ReactPlayer from 'react-player/youtube'
import { Link as Links } from 'react-router-dom';
import ImageGallery from "react-image-gallery";
import ImageZoom from "react-image-zooom";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

const Collections = (props) => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }

    }, []);

    const [displayzoom, setDisplayZoom] = useState("none");
    const [img, setImg] = useState(al1)
    // const [displaytext, setDisplayText] = useState("block");

    let url;

    const zoomin = (e) => {
        // modal.style.display = "block";
        url = e.target.src;
        setImg(url);
        setDisplayZoom("block");

    }

    const zoomout = (e) => {
        // modal.style.display = "none";
        setDisplayZoom("none")
    }

    return (
        <>

            <Header />
            <Whatsapp/>
            <div className="cp" >
                <h4 className='links'>
                    <Links to={"/#collections"}>Collections</Links>
                    <svg id="Слой_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m53.893 512c-10.79 0-20.934-4.204-28.579-11.836-7.632-7.638-11.842-17.79-11.842-28.586s4.211-20.948 11.842-28.582l187.001-186.998-187.001-186.997c-7.632-7.635-11.842-17.786-11.842-28.582s4.211-20.948 11.842-28.582 17.79-11.837 28.579-11.837 20.948 4.201 28.579 11.832l215.58 215.584c7.632 7.635 11.842 17.786 11.842 28.582s-4.211 20.948-11.842 28.582l-215.58 215.58c-7.644 7.636-17.789 11.84-28.579 11.84z" /><path d="m242.526 512c-10.79 0-20.934-4.204-28.579-11.836-7.632-7.638-11.842-17.79-11.842-28.586s4.211-20.948 11.842-28.582l187.001-186.998-187.001-186.997c-7.632-7.635-11.842-17.786-11.842-28.582s4.211-20.948 11.842-28.582 17.79-11.837 28.579-11.837 20.948 4.201 28.579 11.832l215.58 215.584c7.632 7.635 11.842 17.786 11.842 28.582s-4.211 20.948-11.842 28.582l-215.58 215.58c-7.644 7.636-17.789 11.84-28.579 11.84z" /></g></svg>
                    <Links to={props.link}>{props.linkname}</Links>
                </h4>

                <div className="cp-slider">
                    <div className="cp-slider-images">
                        <ImageGallery
                            // onWheelCapture={onScroll}
                            items={props.img}
                            showThumbnails={true}
                            thumbnailPosition={"left"}
                            showNav={true}
                            showIndex={true}
                            disableThumbnailScroll={false}
                            style={{ overflow: "hidden" }}
                            // onMouseOver={(e) => (e.currentTarget.style = { transform: "scale(1.25)", overflow: "hidden" })}
                            // onMouseOut={(e) => (e.currentTarget.style = { transform: "scale(1)", overflow: "hidden" })}
                            onClick={zoomin}
                        />

                        <div className="zoom" style={{ display: displayzoom }}>
                            <div className="zoom-body">
                                <p>click to zoom in</p>
                                <svg onClick={zoomout} className='close' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                            </div>

                            <ImageZoom src={img} zoom="400" />
                        </div>
                    </div>

                    <div className="cp-slider-text">
                        <h3>{props.slider_headline}</h3>
                        <p>{props.slider_text}</p>

                        <div className="cp-slider-text-btn">
                            <Links to={"/contact"}>Contact us for entire collection</Links>
                            <Links to={"/frames"}>Browse Frames</Links>
                        </div>

                    </div>
                </div>
                <div className="cp-text">
                    <p>{props.p1}</p>
                    <p>{props.p2}</p>
                    <p>{props.p3}</p>
                    <p>{props.p4}</p>

                    <ul>
                        <li>Each artwork is carefully handcrafted.</li>
                        <li>Framed by experts, arrives ready to hang if you’ve chosen a frame.</li>
                        <li>Durable & non-toxic paint on canvas</li>
                        <li>Acrylic paint, oil paint and mixed media depending upon the chosen painting</li>
                        <li>Variety of sizes available Offers high-end art at an affordable price</li>
                        <li>Minor differences due to original handwork</li>
                        <li>High customer satisfaction with numerous positive reviews</li>
                    </ul>
                    <p>For Custom sizes, message us on our contact page.</p>

                    <div className="sizechart">
                        <img src={sizechart2} alt="size chart" />
                        <img src={sizechart1} alt="size chart" />

                    </div>
                </div>

                <div className="cp-how">
                    <h3>{props.videoHeadline}</h3>
                    <ReactPlayer url={props.url} width={"100%"} height={"80vh"} />
                    <p>{props.videotext}</p>
                </div>

                <div className="cp-order">
                    <div className="last">
                        <h4>Need additional help? Feel free to reach out!</h4>
                        <Links to={"/contact"}>contact us</Links>
                    </div>
                </div>
            </div>
            <div className="frame9">
                <p>Rate us on Trust Pilot / Google</p>
                <div className="frame9-star">
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.467,23.316,12,17.828,4.533,23.316,7.4,14.453-.063,9H9.151L12,.122,14.849,9h9.213L16.6,14.453Z" /></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.467,23.316,12,17.828,4.533,23.316,7.4,14.453-.063,9H9.151L12,.122,14.849,9h9.213L16.6,14.453Z" /></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.467,23.316,12,17.828,4.533,23.316,7.4,14.453-.063,9H9.151L12,.122,14.849,9h9.213L16.6,14.453Z" /></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.467,23.316,12,17.828,4.533,23.316,7.4,14.453-.063,9H9.151L12,.122,14.849,9h9.213L16.6,14.453Z" /></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m24.043,8.992h-9.214L11.981.114l-2.849,8.878H-.082l7.46,5.453-2.864,8.863,7.467-5.488,7.467,5.488-2.864-8.863,7.46-5.453Zm-12.062,6.346V6.661l1.39,4.331h4.548l-3.683,2.691,1.403,4.344-3.658-2.689Z" />
                    </svg>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default Collections