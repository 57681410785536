import React from 'react'
import Header from '../../../Molecules/Header/Header'
import Footer from '../../../Molecules/Footer/Footer'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./Collections.scss"
import sizechart from "../../../Images/Customized Paintings/size chart.png"
import ReactPlayer from 'react-player/youtube'
import { Link } from 'react-router-dom';
import Collections from './Collections';
import s1 from "../../../Images/Collections/3. Scenery/1.jpg"
import s2 from "../../../Images/Collections/3. Scenery/2.jpg"
import s3 from "../../../Images/Collections/3. Scenery/3.jpg"
import s4 from "../../../Images/Collections/3. Scenery/4.jpg"
import s5 from "../../../Images/Collections/3. Scenery/5.jpg"
import s6 from "../../../Images/Collections/3. Scenery/6.jpg"
import s7 from "../../../Images/Collections/3. Scenery/7.jpg"
import s8 from "../../../Images/Collections/3. Scenery/8.jpg"
import s9 from "../../../Images/Collections/3. Scenery/9.jpg"
import s10 from "../../../Images/Collections/3. Scenery/10.jpg"
import s11 from "../../../Images/Collections/3. Scenery/11.jpg"
import s12 from "../../../Images/Collections/3. Scenery/12.jpg"
import s13 from "../../../Images/Collections/3. Scenery/13.jpg"
import s14 from "../../../Images/Collections/3. Scenery/14.jpg"
import s15 from "../../../Images/Collections/3. Scenery/15.jpg"
import s16 from "../../../Images/Collections/3. Scenery/16.jpg"
import s17 from "../../../Images/Collections/3. Scenery/17.jpg"
import s18 from "../../../Images/Collections/3. Scenery/18.jpg"
import s19 from "../../../Images/Collections/3. Scenery/19.jpg"
import s20 from "../../../Images/Collections/3. Scenery/20.jpg"
import s21 from "../../../Images/Collections/3. Scenery/21.jpg"
import s22 from "../../../Images/Collections/3. Scenery/22.jpg"

const Scenery = () => {

    const images = [
       
        {
            original: s1,
            thumbnail: s1,
        },
        {
            original: s2,
            thumbnail: s2,
        },
        {
            original: s3,
            thumbnail: s3,
        },
        {
            original: s4,
            thumbnail: s4,
        },
        {
            original: s5,
            thumbnail: s5,
        },
        {
            original: s6,
            thumbnail: s6,
        },
        {
            original: s7,
            thumbnail: s7,
        },
        {
            original: s8,
            thumbnail: s8,
        },
        {
            original: s9,
            thumbnail: s9,
        },
        {
            original: s10,
            thumbnail: s10,
        },
        {
            original: s11,
            thumbnail: s11,
        },
        {
            original: s12,
            thumbnail: s12,
        },
        {
            original: s13,
            thumbnail: s13,
        },
        {
            original: s14,
            thumbnail: s14,
        },
        {
            original: s15,
            thumbnail: s15,
        },
        {
            original: s16,
            thumbnail: s16,
        },
        {
            original: s17,
            thumbnail: s17,
        },
        {
            original: s18,
            thumbnail: s18,
        },
        {
            original: s19,
            thumbnail: s19,
        },
        {
            original: s20,
            thumbnail: s20,
        },
        {
            original: s21,
            thumbnail: s21,
        },
        {
            original: s22,
            thumbnail: s22,
        },
    ];


    return (
        <>
            <Collections
                link="/scenery"
                linkname="Scenery"
                img={images}
                slider_headline="Scenery"
                slider_text="From serene landscapes to vibrant
                            sunsets, each painting captures the
                            essence of the outdoors, bringing
                            tranquility and grandeur into any space.
                            Immerse yourself in the splendor of
                            forests, mountains, deserts and oceans."
                p1="Our collection includes many different scenes from peaceful countryside to majestic mountains. It
                        features diverse scenes including serene lakes and ponds, arctic landscapes, northern lights, deserts,
                        and lush forests. There’s a perfect piece for every nature lover and every space."
                p2="We draw inspiration from the diverse and magnificent natural world, creating pieces that show the calm
                        of a forest at dawn, the stillness of a mountain lake, and the bright colors of a coastal sunset. These paintings are more than just pictures—they take you right into nature's most beautiful places."
                p3="Experience the artistry and elegance of our Scenery collection, where each piece tells a story of nature's grandeur, inviting you to explore and find peace in its timeless beauty."
                p4=""
                url="https://youtu.be/bOwmUZ1YeA0?si=YTEA6314o7OIFt_C"
                videoHeadline="From Brush to Canvas: The Art of Handmade Painting"
                videotext="Take a peek at our collection and how it's brought to life. We have thousands of pieces for you to
                        choose from. Can’t decide what will look best? We have a team dedicated to help you choose the best pieces for your space."
            />
        </>
    )
}

export default Scenery