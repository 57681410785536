import React from 'react'
import "./Collections.scss"
import Collections from './Collections';

import u1 from "../../../Images/Collections/2. UAE Heritage/1.jpg"
import u2 from "../../../Images/Collections/2. UAE Heritage/2.jpg"
import u3 from "../../../Images/Collections/2. UAE Heritage/3.jpg"
import u4 from "../../../Images/Collections/2. UAE Heritage/4.jpg"
import u5 from "../../../Images/Collections/2. UAE Heritage/5.jpg"
import u6 from "../../../Images/Collections/2. UAE Heritage/6.jpg"
import u7 from "../../../Images/Collections/2. UAE Heritage/7.jpg"
import u8 from "../../../Images/Collections/2. UAE Heritage/8.jpg"
import u9 from "../../../Images/Collections/2. UAE Heritage/9.jpg"
import u10 from "../../../Images/Collections/2. UAE Heritage/10.jpg"
import u11 from "../../../Images/Collections/2. UAE Heritage/11.jpg"
import u12 from "../../../Images/Collections/2. UAE Heritage/12.jpg"
import u13 from "../../../Images/Collections/2. UAE Heritage/13.jpg"
import u14 from "../../../Images/Collections/2. UAE Heritage/14.jpg"

const RoyalPortraits = () => {

    const images = [
        {
            original: u1,
           thumbnail: u1,
        },
        {
            original: u2,
           thumbnail: u2,
        },
        {
            original: u3,
           thumbnail: u3,
        },
        {
            original: u4,
           thumbnail: u4,
        },
        {
            original: u5,
           thumbnail: u5,
        },
        {
            original: u6,
           thumbnail: u6,
        },
        {
            original: u7,
           thumbnail: u7,
        },
        {
            original: u8,
           thumbnail: u8,
        },
        {
            original: u9,
           thumbnail: u9,
        },
        {
            original: u10,
           thumbnail: u10,
        },
        {
            original: u11,
           thumbnail: u11,
        },
        {
            original: u12,
           thumbnail: u12,
        },
        {
            original: u13,
           thumbnail: u13,
        },
        {
            original: u14,
           thumbnail: u14,
        },
    ];

    return (
        <>
            <Collections
                link="/uae-heritage"
                linkname="UAE Heritage"
                img={images}
                slider_headline="UAE Heritage"
                slider_text="Explore the rich heritage of the UAE
                            through captivating artworks that
                            radiate prestige and grace. Enhance your business with the everlasting beauty of our UAE Heritage collection, where
                            tradition meets sophistication."
                p1="Enrich your surroundings with the majestic allure of glazing hand-painted portraits and scenes from
                        UAE’s history. Our original artworks pay homage to the famous rulers of the UAE, capturing their
                        enduring legacy of power, glory, and conquest. Our paintings serve as timeless tributes to the rich
                        history and cultural heritage of the UAE."
                p2="Chitrvilas royal portraits hold a distinguished reputation within the UAE's governmental and public sectors. We have a proven track record of supplying our prestigious portraits to various establishments, including government entities, hotels, hospitals, and offices."
                p3=""
                p4=""
                url="https://youtu.be/3b_dpIizcLY?si=QGgqUhQ4QzSxjDFO"
                videoHeadline="Handmade Painting: The Artist’s Journey from Blank Canvas to Art"
                videotext="Explore our collection and experience UAE’s history. With hand-picked, and original options
                        available, finding the perfect piece is simple. Unsure where to begin? Our dedicated team is here
                        to offer expert advice and assist you in selecting the ideal pieces for your space."
            />
        </>
    )
}

export default RoyalPortraits