import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"
// import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyA8M2_bwphKI2HGYptETPQZNDigwFoz-aM",
    authDomain: "sketchostorypaintings-da179.firebaseapp.com",
    projectId: "sketchostorypaintings-da179",
    storageBucket: "sketchostorypaintings-da179.appspot.com",
    messagingSenderId: "81032668582",
    appId: "1:81032668582:web:f4284eb23e0073d9095b67",
    measurementId: "G-5MXESNNHC3"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
