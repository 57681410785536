import React, {useEffect} from 'react'
import Header from '../../Molecules/Header/Header'
import Footer from '../../Molecules/Footer/Footer'
import Whatsapp from '../../Molecules/Whatsapp/Whatsapp'

const ReturnPolicy = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);


    return (
        <>
            <Header />
            <Whatsapp />
            <div className='policy'>
                <h2>Returns and Replacement Policy</h2>
                <p>At Sketchostory Paintings, we are committed to ensuring your satisfaction with our artwork. This
                    policy outlines the conditions under which you may return items and request a replacement.</p>

                <ol>
                    <li>Eligibility for Returns
                        <ul>
                            <li><b>Standard Products:</b>We accept returns only if the product arrives damaged or is lost
                                during shipping. You may return the item within <strong>7 days</strong> of receiving it for a replacement.
                                The item must be in the original packaging.</li>
                            <li><b>Custom or Personalized Items:</b>Due to the bespoke nature of custom and personalized
                                items, these products are non-returnable unless they arrive damaged or defective.
                                Please ensure all custom order details are correct before finalizing your purchase.</li>
                        </ul>
                    </li>

                    <li>Non-Returnable Items
                        <p>The following items are non-returnable:</p>
                        <ul>
                            <li>Gift Cards</li>
                            <li>Items purchased on sale or with a discount code</li>
                            <li>Final sale items</li>
                            <li>Custom or personalized items, unless they are defective or damaged</li>
                        </ul>
                    </li>

                    <li>Return and Replacement Process
                        <p>To initiate a return and request a replacement, please follow these steps:</p>

                        <ol className='return'>
                            <li><b>Contact Us:</b> Email our customer support at <i>info@sketchostorypaintings.com</i> within <strong>3
                                days</strong> of receiving your order to request a return. Include your order number, the item(s)
                                you wish to return, and the reason for the return.</li>
                            <li><b>Receive Return Authorization:</b>Once your return request is approved, you will receive
                                further instructions on how to return your item(s).</li>
                            <li><b>Package Your Return:</b>Securely package the item(s) in the original packaging, including
                                any documentation, accessories, or certificates that came with the product.</li>
                            <li><b>Ship Your Return:</b>Use a trackable and insured shipping method to send the item(s)
                                back to us. The return shipping costs are the responsibility of the customer, except in
                                cases where the return is due to our error or a defective item.</li>
                        </ol>
                    </li>

                    <li>Replacement Process

                        <ul>
                            <li><b>Inspection:</b>Once we receive your returned item(s), we will inspect them to ensure they
                                meet the return eligibility criteria. Items that do not meet these criteria may be returned to
                                the customer without a replacement.</li>
                            <li><b>Replacement Issuance:</b>If the return is approved, a replacement item of the same value
                                will be processed and shipped to you within <b>15 to 20 business days</b>.</li>
                        </ul>
                    </li>

                    <li>
                        Damaged or Defective Items

                        <ul>
                            <li><b>Reporting Damages:</b> If your item arrives damaged or defective, please contact us within
                                <strong>3 days</strong> of receiving your order. Provide your order number, a description of the issue, and
                                photographs of the damaged or defective item(s).</li>
                            <li><b>Replacement Only:</b>We will offer you a replacement item at no additional cost. We may
                                require the return of the damaged item before processing the replacement.</li>
                        </ul>
                    </li>

                    <li>Late or Missing Replacements
                        <ul>
                            <li><b>Processing Time:</b>If you haven’t received your replacement after the stated processing
                                time, please contact our customer support team at info@sketchostorypaintings.com for
                                assistance.</li>
                        </ul>
                    </li>

                    <li>
                        Return Shipping Costs

                        <ul>
                            <li><b>Customer Responsibility:</b>Return shipping costs are the responsibility of the customer,
                                except in cases where the return is due to our error or a defective item.</li>
                            <li><b>International Returns:</b>For international orders, the customer is responsible for return
                                shipping costs, customs duties, and taxes.</li>
                        </ul>
                    </li>

                    <li>Final Sale and Clearance Items</li>
                    <p>Items marked as “Final Sale” or purchased from our clearance section are not eligible for return
                        or replacement. Please carefully review your order before completing your purchase.</p>

                    <li>Contact Us</li>
                    <p>If you have any questions or need further assistance with your return or replacement, please
                        contact our customer support team at info@sketchostorypaintings.com. We are here to help you
                        and ensure that your experience with Sketchostory Paintings is positive.</p>

                    <li>Changes to This Shipping Policy

                        <ul>
                            <li><b>Policy Updates:</b>We may update this Return Policy periodically to reflect changes in our
                                return practices or due to operational, legal, or regulatory requirements. We reserve the
                                right to update or modify this Return Policy at any time, for any reason, at our sole
                                discretion. All updates will be posted on this page, and we encourage you to review this
                                policy regularly.</li>
                            <li><b>Effective Date:</b>Changes to this policy are effective immediately upon posting on this page.</li>
                        </ul>
                    </li>

                </ol>
            </div>
            <Footer />
        </>
    )
}

export default ReturnPolicy