import React from 'react'

const Title = (props) => {
    return (
        <div className="title" id={props.id}>
            <h4>
                {props.title}
                <div className="shapes">
                    <div className="circle4"></div>
                    <div className="circle3"></div>
                    <div className="line"></div>
                    <div className="circle1"></div>
                    <div className="circle2"></div>
                </div>
            </h4>
        </div>
    )
}

export default Title