// components =============================
import React from 'react'
import { Link } from 'react-router-dom'
// components ends ========================

// scss =============================
import "./Footer.scss"
// scss ends ========================

// images =============================
import logo from "../../Images/Logo/footer logo.png"
import instagram from "../../Images/Icons/instagram.jpg"
import facebook from "../../Images/Icons/facebook.jpg"
import youtube from "../../Images/Icons/youtube.jpg"
import twitter from "../../Images/Icons/twitter.jpg"
// images ends ========================



const Header = () => {
  const up = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
  return (
    <>
      <div className="footer">
        <div className="footer-body">
          <div className="footer-left">

            <div className="footer-logo">
              <img src={logo} alt="logo" />

            </div>

            <div className="footer-social">
              <h3>Social Media</h3>
              <div className="footer-social-icons">
                {/* <a href="#">
                  <img src={facebook} alt="facebook" />
                </a> */}
                <a href="https://www.instagram.com/sketchostory_paintings?igsh=M2RmcXBlbGl5bGVz">
                  <img src={instagram} alt="instagram" />
                </a>
                <a href="http://www.youtube.com/@SketchostoryPaintings">
                  <img src={youtube} alt="youtube" />
                </a>
                {/* <a href="#">
                  <img src={twitter} alt="twitter" />
                </a> */}
              </div>
            </div>
          </div>

          <div className="footer-right">
            <div className="footer-links">
              <h3>Quick Links</h3>
              <ul>
                <li><Link to={"/#about"}>About us</Link></li>
                {/* <li><a href="#">FAQs</a></li> */}
                <li><Link to={"/#contact"}>Contact us</Link></li>
                <li><Link onClick={up} to={"/shipping-policy"}>Shipping Policy</Link></li>
                <li><Link onClick={up} to={"/return-policy"}>Return Policy</Link></li>
                <li><Link onClick={up} to={"/terms-of-service"}>Terms of Service</Link></li>
                <li><Link onClick={up} to={"/privacy-policy"}>Privacy Policy</Link></li>

              </ul>
            </div>

            <div className="footer-links">
              <h3>Shop</h3>
              <ul>
                <li><Link onClick={up} to={"/contact"}>Corporate Services</Link></li>
                <li><Link onClick={up} to={"/customized-paintings"}>Customized Artwork</Link></li>
                <li><Link onClick={up} to={"/frames"}>Frames</Link></li>
                <li><Link onClick={up} to={"/#collections"}>Collections</Link></li>
              </ul>
            </div>
          </div>


        </div>
        <div className="footer-copyright">
          <p>Copyright &#169; {new Date().getFullYear()} by <a target='_blank' href="https://www.aptaledesigns.com"> Aptale Designs</a>
          </p>
        </div>
      </div>
    </>
  )
}

export default Header