import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./Collections.scss"
import Collections from './Collections';
import a1 from "../../../Images/Collections/10. Animals/1.jpg"
import a2 from "../../../Images/Collections/10. Animals/2.jpg"
import a3 from "../../../Images/Collections/10. Animals/3.jpg"
import a4 from "../../../Images/Collections/10. Animals/4.jpg"
import a5 from "../../../Images/Collections/10. Animals/5.jpg"
import a6 from "../../../Images/Collections/10. Animals/6.jpg"
import a7 from "../../../Images/Collections/10. Animals/7.jpg"
import a8 from "../../../Images/Collections/10. Animals/8.jpg"
import a9 from "../../../Images/Collections/10. Animals/9.jpg"
import a10 from "../../../Images/Collections/10. Animals/10.jpg"
import a11 from "../../../Images/Collections/10. Animals/11.jpg"
import a12 from "../../../Images/Collections/10. Animals/12.jpg"
import a13 from "../../../Images/Collections/10. Animals/13.jpg"
import a14 from "../../../Images/Collections/10. Animals/14.jpg"
import a15 from "../../../Images/Collections/10. Animals/15.jpg"
import a16 from "../../../Images/Collections/10. Animals/16.jpg"
import a17 from "../../../Images/Collections/10. Animals/17.jpg"
import a18 from "../../../Images/Collections/10. Animals/18.jpg"
import a19 from "../../../Images/Collections/10. Animals/19.jpg"
import a20 from "../../../Images/Collections/10. Animals/20.jpg"
import a21 from "../../../Images/Collections/10. Animals/21.jpg"
import a22 from "../../../Images/Collections/10. Animals/22.jpg"
import a23 from "../../../Images/Collections/10. Animals/23.jpg"
import a24 from "../../../Images/Collections/10. Animals/24.jpg"
import a25 from "../../../Images/Collections/10. Animals/25.jpg"
import a26 from "../../../Images/Collections/10. Animals/26.jpg"

const Horses = () => {

        const images = [
                {
                        original: a1,
                        thumbnail: a1,
                    },
                    {
                        original: a2,
                        thumbnail: a2,
                    },
                    {
                        original: a3,
                        thumbnail: a3,
                    },
                    {
                        original: a4,
                        thumbnail: a4,
                    },
                    {
                        original: a5,
                        thumbnail: a5,
                    },
                    {
                        original: a6,
                        thumbnail: a6,
                    },
                    {
                        original: a7,
                        thumbnail: a7,
                    },
                    {
                        original: a8,
                        thumbnail: a8,
                    },
                    {
                        original: a9,
                        thumbnail: a9,
                    },
                    {
                        original: a10,
                        thumbnail: a10,
                    },
                    {
                        original: a11,
                        thumbnail: a11,
                    },
                    {
                        original: a12,
                        thumbnail: a12,
                    },
                    {
                        original: a13,
                        thumbnail: a13,
                    },
                    {
                        original: a14,
                        thumbnail: a14,
                    },
                    {
                        original: a15,
                        thumbnail: a15,
                    },
                    {
                        original: a16,
                        thumbnail: a16,
                    },
                    {
                        original: a17,
                        thumbnail: a17,
                    },
                    {
                        original: a18,
                        thumbnail: a18,
                    },
                    {
                        original: a19,
                        thumbnail: a19,
                    },
                    {
                        original: a20,
                        thumbnail: a20,
                    },
                    {
                        original: a21,
                        thumbnail: a21,
                    },
                    {
                        original: a22,
                        thumbnail: a22,
                    },
                    {
                        original: a23,
                        thumbnail: a23,
                    },
                    {
                        original: a24,
                        thumbnail: a24,
                    },
                    {
                        original: a25,
                        thumbnail: a25,
                    },
                    {
                        original: a26,
                        thumbnail: a26,
                    },
        ]
        return (
                <>
                        <Collections
                                link="/animals"
                                linkname="Animals"
                               img={images}
                                slider_headline="Animals"
                                slider_text="The Animals collection captures the
                            essence of wildlife in a way that
                            resonates with both art lovers and
                            nature enthusiasts. Each piece in this
                            collection serves as a reminder of the beauty and diversity of the natural world, bringing a touch of the wild into
                            your home or office."
                                p1="Discover the wild beauty and vibrant energy of our Animals collection, where each painting brings the majesty and diversity of the animal kingdom to life. These artworks capture the essence of various
                        creatures, showcasing their grace, strength, and unique characteristics through masterful brushstrokes
                        and vivid colors."
                                p2="Our talented artists draw inspiration from nature, creating pieces that not only celebrate the splendor of animals but also evoke a deeper connection to the natural world. The intricate details and dynamic
                        compositions make each painting a captivating focal point, perfect for any space."
                                p3="Whether displayed in a living room, office, or gallery, these artworks add a touch of the wild to your
                        environment, inspiring awe and admiration. Transform your surroundings with the enchanting presence."
                                p4=""
                                url="https://youtu.be/DJqvCpm8L4M?si=QEfXm9bLVOc9ARqh"
                                videoHeadline="Watch a Blank Canvas Transform into a Stunning Painting"
                                videotext="Discover our extensive collection and marvel at its wide-ranging diversity. With thousands of options to choose from, finding the perfect piece is simple. Unsure where to begin? Our dedicated
                    team is ready to assist you in selecting the ideal pieces for your space."
                        />
                </>
        )
}

export default Horses