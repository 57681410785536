import React, { useEffect, useState } from 'react'
import Header from '../../Molecules/Header/Header'
import Footer from '../../Molecules/Footer/Footer'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./Products.scss"

import sizechart1 from "../../Images/Customized Paintings/size chart.png"
import sizechart2 from "../../Images/Customized Paintings/Picture4 (1).png"
import ReactPlayer from 'react-player/youtube'
import artboard from "../../Images/Customized Paintings/icons8-painting-68.png"
import { Link } from 'react-router-dom';
import ImageGallery from "react-image-gallery";
import ImageZoom from "react-image-zooom";
import c1 from "../../Images/Customized Paintings/1.jpg"
import c2 from "../../Images/Customized Paintings/2.jpg"
import c3 from "../../Images/Customized Paintings/3.jpg"
import c4 from "../../Images/Customized Paintings/4.jpg"
import c5 from "../../Images/Customized Paintings/5.jpg"
import c6 from "../../Images/Customized Paintings/6.jpg"
import c7 from "../../Images/Customized Paintings/7.jpg"
import c8 from "../../Images/Customized Paintings/8.jpg"
import c9 from "../../Images/Customized Paintings/9.jpg"
import c10 from "../../Images/Customized Paintings/10.jpg"
import c11 from "../../Images/Customized Paintings/11.jpg"
import c12 from "../../Images/Customized Paintings/12.jpg"
import c13 from "../../Images/Customized Paintings/13.jpg"
import c14 from "../../Images/Customized Paintings/14.jpg"
import c15 from "../../Images/Customized Paintings/15.jpg"
import c16 from "../../Images/Customized Paintings/16.jpg"
import c17 from "../../Images/Customized Paintings/17.jpg"
import c18 from "../../Images/Customized Paintings/18.jpg"
import c19 from "../../Images/Customized Paintings/19.jpg"
import c20 from "../../Images/Customized Paintings/20.jpg"
import c21 from "../../Images/Customized Paintings/21.jpg"
import c22 from "../../Images/Customized Paintings/22.jpg"
import c23 from "../../Images/Customized Paintings/23.jpg"
import c24 from "../../Images/Customized Paintings/24.jpg"
import Whatsapp from "../../Molecules/Whatsapp/Whatsapp"

const CustomizedPainting = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const images = [
    {
      original: c1,
      thumbnail: c1,
    },
    {
      original: c2,
      thumbnail: c2,
    },
    {
      original: c3,
      thumbnail: c3,
    },
    {
      original: c4,
      thumbnail: c4,
    },
    {
      original: c5,
      thumbnail: c5,
    },
    {
      original: c6,
      thumbnail: c6,
    },

    {
      original: c7,
      thumbnail: c7,
    },
    {
      original: c8,
      thumbnail: c8,
    },
    {
      original: c9,
      thumbnail: c9,
    },
    {
      original: c10,
      thumbnail: c10,
    },

    {
      original: c11,
      thumbnail: c11,
    },
    {
      original: c12,
      thumbnail: c12,
    },
    {
      original: c13,
      thumbnail: c13,
    },
    {
      original: c14,
      thumbnail: c14,
    },
    {
      original: c15,
      thumbnail: c15,
    },
    {
      original: c16,
      thumbnail: c16,
    },

    {
      original: c17,
      thumbnail: c17,
    },
    {
      original: c18,
      thumbnail: c18,
    },
    {
      original: c19,
      thumbnail: c19,
    },
    {
      original: c20,
      thumbnail: c20,
    },
    {
      original: c21,
      thumbnail: c21,
    },
    {
      original: c22,
      thumbnail: c22,
    },
    {
      original: c23,
      thumbnail: c23,
    },
    {
      original: c24,
      thumbnail: c24,
    },

  ];

  const [displayzoom, setDisplayZoom] = useState("none");
  const [img, setImg] = useState(sizechart1)
  let url;

  const zoomin = (e) => {
    // modal.style.display = "block";
    url = e.target.src;
    setImg(url);
    setDisplayZoom("block");

  }

  const zoomout = (e) => {
    // modal.style.display = "none";
    setDisplayZoom("none")
  }

  return (
    <>
      <Header />
      <Whatsapp />
      <div className="customized_painting">
        <div className="cp-slider">

          <div className="cp-slider-images">
            <ImageGallery
              items={images}
              showThumbnails={true}
              thumbnailPosition={"left"}
              showNav={true}
              showIndex={true}
              disableThumbnailScroll={true}
              onClick={zoomin}
            />

            <div className="zoom" style={{ display: displayzoom }}>
              <div className="zoom-body">
                <p>click to zoom in</p>
                <svg onClick={zoomout} className='close' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
              </div>

              <ImageZoom src={img} zoom="400" />
            </div>
          </div>
          <div className="cp-slider-text">
            <h3>Customized Painting</h3>
            <p>Are you looking for art that seamlessly
              complements your decor, taste, and
              style? At Sketchostory
              Paintings, our artists create
              stunning, customized paintings that harmonize effortlessly with your room's
              aesthetic, ensuring a natural and
              sophisticated fit.</p>

            <p>Whether you want to customize a family portrait or you’re looking for a reproduction, we can do it. If you
              can think of it, we can paint it.</p>
            <p>That's the beauty of Sketchostory
              Paintings' custom painting service. You generate your own paintings.</p>


            <div className="cp-slider-text-btn">
              <a href="#how-to-order">How to Order</a>
              <Link to={"/frames"}>Browse Frames</Link>
            </div>

          </div>
        </div>
        <div className="cp-text">
          <p>Capture the essence of your family with our exquisite customized family portraits. We specialize in personalized artworks that celebrate the unique bond and cherished moments shared among loved ones. Our talented artists bring your vision to life, creating beautiful pieces that perfectly match your taste and
            décor. Whether you have a specific theme, color palette, or size in mind, we craft masterpieces tailored to
            your needs.</p>
          <p><b>From cherished memories to imaginative concepts, we transform your ideas into
            one-of-a-kind artworks that elevate your space and reflect your individuality.</b></p>
          <p>For Custom sizes, message us on our contact page.</p>
          <div className="sizechart">
            <img src={sizechart2} alt="size chart" />
            <img src={sizechart1} alt="size chart" />
          </div>
        </div>

        <div className="cp-how">
          <h3>How we turn your visions into paintings</h3>
          <ReactPlayer url='https://youtu.be/bOwmUZ1YeA0?si=YTEA6314o7OIFt_C' width={"100%"} height={"70vh"} />
          <p>Perfect for commemorating milestones or simply celebrating your loved ones, our
            customized family portraits add a personal and timeless touch to your home decor.
            Let us create beautiful, unique artwork that you or your loved ones will treasure for
            generations.</p>
        </div>

        <div className="cp-order" id="how-to-order">
          <h3>How to order</h3>
          <div className="cp-order-text">
            <div className="cp-order-body">
              <div className="cp-order-text-icon">
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M12,24C5.383,24,0,18.617,0,12S5.383,0,12,0s12,5.383,12,12-5.383,12-12,12Zm0-22C6.486,2,2,6.486,2,12s4.486,10,10,10,10-4.486,10-10S17.514,2,12,2Zm2.747,11.971l-1.069,.851c-2.071-.791-3.599-2.256-4.5-4.5l.851-1.069c.446-.446,.446-1.17,0-1.616l-1-1.302c-.446-.446-1.17-.446-1.616,0l-.6,.522c-.552,.551-.813,1.277-.813,2,0,4,5.429,9.143,9.143,9.143,.723,0,1.449-.261,2-.813l.522-.6c.446-.446,.446-1.17,0-1.616l-1.302-1c-.446-.446-1.17-.446-1.616,0Z" /></svg>
                <span>Step 1</span>
              </div>
              <div className="cp-order-text-text">
                <h4>Contact Us</h4>
                <p>Reach out to discuss your
                  requirements. If unsure or
                  need advice, we’re happy to
                  provide suggestions</p>
              </div>
            </div>
            <div className="cp-order-body">
              <div className="cp-order-text-icon">
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                  <path d="m3,22h5v2H3c-1.654,0-3-1.346-3-3v-5h2v5c0,.552.448,1,1,1Zm19-1c0,.552-.448,1-1,1h-5v2h5c1.654,0,3-1.346,3-3v-5h-2v5ZM21,0h-5v2h5c.552,0,1,.448,1,1v5h2V3c0-1.654-1.346-3-3-3ZM2,3c0-.552.448-1,1-1h5V0H3C1.346,0,0,1.346,0,3v5h2V3Zm10,11c1.105,0,2-.895,2-2s-.895-2-2-2-2,.895-2,2,.895,2,2,2Zm-3.5-7h.464l1.33-2h3.412l1.33,2h.464c1.379,0,2.5,1.121,2.5,2.5v7.5H6v-7.5c0-1.379,1.121-2.5,2.5-2.5Zm7,2h-7c-.275,0-.5.225-.5.5v5.5h8v-5.5c0-.275-.225-.5-.5-.5Z" />
                </svg>

                <span>Step 2</span>
              </div>
              <div className="cp-order-text-text">
                <h4>Reference Images</h4>
                <p>Provide images for customized
                  paintings of loved ones or
                  famous artworks. Discuss your concepts with our team for our artists to bring to life</p>
              </div>
            </div>
            <div className="cp-order-body">
              <div className="cp-order-text-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M272.2 64.6l-51.1 51.1c-15.3 4.2-29.5 11.9-41.5 22.5L153 161.9C142.8 171 129.5 176 115.8 176H96V304c20.4 .6 39.8 8.9 54.3 23.4l35.6 35.6 7 7 0 0L219.9 397c6.2 6.2 16.4 6.2 22.6 0c1.7-1.7 3-3.7 3.7-5.8c2.8-7.7 9.3-13.5 17.3-15.3s16.4 .6 22.2 6.5L296.5 393c11.6 11.6 30.4 11.6 41.9 0c5.4-5.4 8.3-12.3 8.6-19.4c.4-8.8 5.6-16.6 13.6-20.4s17.3-3 24.4 2.1c9.4 6.7 22.5 5.8 30.9-2.6c9.4-9.4 9.4-24.6 0-33.9L340.1 243l-35.8 33c-27.3 25.2-69.2 25.6-97 .9c-31.7-28.2-32.4-77.4-1.6-106.5l70.1-66.2C303.2 78.4 339.4 64 377.1 64c36.1 0 71 13.3 97.9 37.2L505.1 128H544h40 40c8.8 0 16 7.2 16 16V352c0 17.7-14.3 32-32 32H576c-11.8 0-22.2-6.4-27.7-16H463.4c-3.4 6.7-7.9 13.1-13.5 18.7c-17.1 17.1-40.8 23.8-63 20.1c-3.6 7.3-8.5 14.1-14.6 20.2c-27.3 27.3-70 30-100.4 8.1c-25.1 20.8-62.5 19.5-86-4.1L159 404l-7-7-35.6-35.6c-5.5-5.5-12.7-8.7-20.4-9.3C96 369.7 81.6 384 64 384H32c-17.7 0-32-14.3-32-32V144c0-8.8 7.2-16 16-16H56 96h19.8c2 0 3.9-.7 5.3-2l26.5-23.6C175.5 77.7 211.4 64 248.7 64H259c4.4 0 8.9 .2 13.2 .6zM544 320V176H496c-5.9 0-11.6-2.2-15.9-6.1l-36.9-32.8c-18.2-16.2-41.7-25.1-66.1-25.1c-25.4 0-49.8 9.7-68.3 27.1l-70.1 66.2c-10.3 9.8-10.1 26.3 .5 35.7c9.3 8.3 23.4 8.1 32.5-.3l71.9-66.4c9.7-9 24.9-8.4 33.9 1.4s8.4 24.9-1.4 33.9l-.8 .8 74.4 74.4c10 10 16.5 22.3 19.4 35.1H544zM64 336a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm528 16a16 16 0 1 0 0-32 16 16 0 1 0 0 32z" /></svg>
                <span>Step 3</span>
              </div>
              <div className="cp-order-text-text">
                <h4>Mock-Up</h4>
                <p>Receive a free mock-up upon order confirmation</p>
              </div>
            </div>
            <div className="cp-order-body">
              <div className="cp-order-text-icon">
                <img src={artboard} alt="icon" />
                <span>Step 4</span>
              </div>
              <div className="cp-order-text-text">
                <h4>Create Your Masterpiece</h4>
                <p>Once decided, our artists
                  will hand-paint your unique
                  masterpiece</p>
              </div>
            </div>
          </div>
          <p>Our skilled artists work closely with you to understand your vision, ensuring each portrait reflects
            the personalities and emotions that make your family special. Whether you prefer a traditional or
            contemporary style, we use high-quality materials and meticulous craftsmanship to bring your
            family portrait to life.</p>
          <div className="last">
            <h4>Need additional help? Feel free to reach out!</h4>
            <Link to={"/contact"}>contact us</Link>
          </div>
        </div>
      </div>
      <div className="frame9">
        <p>Rate us on Trust Pilot / Google</p>
        <div className="frame9-star">
          <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.467,23.316,12,17.828,4.533,23.316,7.4,14.453-.063,9H9.151L12,.122,14.849,9h9.213L16.6,14.453Z" /></svg>
          <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.467,23.316,12,17.828,4.533,23.316,7.4,14.453-.063,9H9.151L12,.122,14.849,9h9.213L16.6,14.453Z" /></svg>
          <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.467,23.316,12,17.828,4.533,23.316,7.4,14.453-.063,9H9.151L12,.122,14.849,9h9.213L16.6,14.453Z" /></svg>
          <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.467,23.316,12,17.828,4.533,23.316,7.4,14.453-.063,9H9.151L12,.122,14.849,9h9.213L16.6,14.453Z" /></svg>
          <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
            <path d="m24.043,8.992h-9.214L11.981.114l-2.849,8.878H-.082l7.46,5.453-2.864,8.863,7.467-5.488,7.467,5.488-2.864-8.863,7.46-5.453Zm-12.062,6.346V6.661l1.39,4.331h4.548l-3.683,2.691,1.403,4.344-3.658-2.689Z" />
          </svg>

        </div>
      </div>
      <Footer />
    </>
  )
}

export default CustomizedPainting