import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { signOut } from "firebase/auth";
import { auth } from './firebase';
import logoimg from "../Images/Logo/logo.png"
import logotext from "../Images/Logo/logo text.png"
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "./Admin.scss"

const AdminHeader = () => {
    let navigate = useNavigate();

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/admin-login");
            console.log("Signed out successfully");
            toast.success("Logged out successfully");
        }).catch((error) => {
            console.log(error);
            alert(error);
        });
    }

    return (
        <>
            <div className="admin-header">

                <Link to={"/admin"} class="logo">
                    <img src={logoimg} alt="logo" className='logoimg' />
                    <img src={logotext} alt="logo" className='logotext' />
                </Link>
                <div className="btn">
                    <Link to={"/admin-reset-password"} className='reset'>Reset Password</Link>
                    <button className='logout' onClick={handleLogout}>Logout</button>
                </div>


            </div>

        </>
    )
}

export default AdminHeader