import Home from './Components/Pages/Home/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./Components/Sass/main.scss"
import CustomizedPainting from './Components/Pages/Products/CustomizedPainting';
import Frames from './Components/Pages/Products/Frames';
import RoyalPortraits from './Components/Pages/Products/Collections/RoyalPortraits';
import AlArab from "./Components/Pages/Products/Collections/AlArab"
import Animals from "./Components/Pages/Products/Collections/Animals"
import ClassicalIndian from "./Components/Pages/Products/Collections/ClassicalIndian"
import Flowers from "./Components/Pages/Products/Collections/Flowers"
import GautamBuddha from "./Components/Pages/Products/Collections/GautamBuddha"
import Horses from "./Components/Pages/Products/Collections/Horses"
import IslamicArt from "./Components/Pages/Products/Collections/IslamicArt"
import MiddleEastern from "./Components/Pages/Products/Collections/MiddleEastern"
import Renaissance from "./Components/Pages/Products/Collections/Renaissance"
import Scenery from "./Components/Pages/Products/Collections/Scenery"
import UAEHeritage from "./Components/Pages/Products/Collections/UAEHeiritage"
import TermsOfService from './Components/Pages/Policies/TermsOfService';
import ShippingPolicy from './Components/Pages/Policies/ShippingPolicy';
import ReturnPolicy from './Components/Pages/Policies/ReturnPolicy';
import PrivacyPolicy from './Components/Pages/Policies/PrivacyPolicy';
import Contact from './Components/Pages/Contact/Contact';
import Login from './Components/AdminPanel/Login';
import Admin from './Components/AdminPanel/Admin';
import ResetPassword from './Components/AdminPanel/ResetPassword';

function App() {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/customized-paintings' element={<CustomizedPainting />} />
          <Route path='/frames' element={<Frames />} />

          {/* policies */}
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/return-policy' element={<ReturnPolicy />} />
          <Route path='/shipping-policy' element={<ShippingPolicy />} />
          <Route path='/terms-of-service' element={<TermsOfService />} />

          {/* Collections */}
          <Route path='/royal-portraits' element={<RoyalPortraits />} />
          <Route path='/al-arab' element={<AlArab />} />
          <Route path='/animals' element={<Animals />} />
          <Route path='/classical-indian' element={<ClassicalIndian />} />
          <Route path='/flowers' element={<Flowers />} />
          <Route path='/gautam-buddha' element={<GautamBuddha />} />
          <Route path='/horses' element={<Horses />} />
          <Route path='/islamic-art' element={<IslamicArt />} />
          <Route path='/middle-eastern' element={<MiddleEastern />} />
          <Route path='/renaissance' element={<Renaissance />} />
          <Route path='/scenery' element={<Scenery />} />
          <Route path='/uae-heritage' element={<UAEHeritage />} />

          {/* Admin */}
          <Route path='/admin-login' element={<Login />} />
          <Route path='/admin' element={<Admin />} />
          <Route path='/admin-reset-password' element={<ResetPassword />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
