import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./Collections.scss"
import Collections from './Collections';
import c1 from "../../../Images/Collections/7. Classic Indian/1.jpg"
import c2 from "../../../Images/Collections/7. Classic Indian/2.jpg"
import c3 from "../../../Images/Collections/7. Classic Indian/3.jpg"
import c4 from "../../../Images/Collections/7. Classic Indian/4.jpg"
import c5 from "../../../Images/Collections/7. Classic Indian/5.jpg"
import c6 from "../../../Images/Collections/7. Classic Indian/6.jpg"
import c7 from "../../../Images/Collections/7. Classic Indian/7.jpg"
import c8 from "../../../Images/Collections/7. Classic Indian/8.jpg"
import c9 from "../../../Images/Collections/7. Classic Indian/9.jpg"
import c10 from "../../../Images/Collections/7. Classic Indian/10.jpg"
import c11 from "../../../Images/Collections/7. Classic Indian/11.jpg"
import c12 from "../../../Images/Collections/7. Classic Indian/12.jpg"
import c13 from "../../../Images/Collections/7. Classic Indian/13.jpg"

const ClassicalIndian = () => {

    const images = [
        {
            original: c1,
            thumbnail: c1,
        },
        {
            original: c2,
            thumbnail: c2,
        },
        {
            original: c3,
            thumbnail: c3,
        },
        {
            original: c4,
            thumbnail: c4,
        },
        {
            original: c5,
            thumbnail: c5,
        },
        {
            original: c6,
            thumbnail: c6,
        },
        {
            original: c7,
            thumbnail: c7,
        },
        {
            original: c8,
            thumbnail: c8,
        },
        {
            original: c9,
            thumbnail: c9,
        },
        {
            original: c10,
            thumbnail: c10,
        },
        {
            original: c11,
            thumbnail: c11,
        },
        {
            original: c12,
            thumbnail: c12,
        },
        {
            original: c13,
            thumbnail: c13,
        },
    ];




    return (
        <>
            <Collections
                link="/classical-indian"
                linkname="Classical Indian"
                img={images}
                slider_headline="Classical Indian"
                slider_text="The Classical Indian collection is a homage to India's rich cultural and
                            artistic heritage, featuring paintings that depict the country's vibrant traditions
                            and historical splendor. Each piece in
                            this collection is a gateway to India's past, offering a glimpse into its diverse
                            cultural tapestry and timeless beauty."
                p1="Step into the rich cultural heritage of India with our Classical Indian collection, where each painting
                        reflects the vibrant traditions and historical grandeur of the subcontinent. These artworks capture the
                        essence of India's diverse cultural tapestry, from intricate classical dances to majestic temples, through masterful brushstrokes and vivid colors."
                p2="Our artists draw inspiration from India's rich history and folklore, creating pieces that celebrate its
                        artistic legacy and spiritual depth. The detailed compositions and vibrant palettes make each painting a
                        captivating addition to any setting, whether it be a home, office, or gallery."
                p3="Immerse yourself in the timeless beauty and profound cultural significance of our Classical Indian
                        collection, and let the grandeur of India's heritage elevate your space."
                p4=""
                url="https://youtu.be/3b_dpIizcLY?si=QGgqUhQ4QzSxjDFO"
                videoHeadline="Artist’s Hand at Work: The Beauty of Handmade Painting"
                videotext="Dive into our extensive collection and witness its diverse spectrum. With thousands of options
                        available, discovering the perfect piece is a breeze. Need assistance? Our specialized team is on
                        standby to guide you in curating the perfect selection for your space."
            />
        </>
    )
}

export default ClassicalIndian