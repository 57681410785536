import React, {useEffect} from 'react'
import Header from '../../Molecules/Header/Header'
import Footer from '../../Molecules/Footer/Footer'
import Whatsapp from '../../Molecules/Whatsapp/Whatsapp'

const ShippingPolicy = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    return (
        <>
            <Header />
            <Whatsapp />
            <div className='policy'>
                <h2>Shipping Policy for Sketchostory Paintings</h2>
                <p>At Sketchostory Paintings, we are dedicated to ensuring that your artwork arrives safely and
                    promptly. This detailed Shipping Policy provides comprehensive information about our shipping
                    process, including order processing, delivery times, costs, and other important aspects of
                    shipping your order.</p>

                <ol>
                    <li>Order Processing
                        <ul>
                            <li><b>Standard Processing Time:</b>Orders are typically processed within [15 to 20] business
                                days. This includes verifying your order, preparing your artwork for shipment, and handing
                                it over to the courier.</li>
                            <li><b>Custom and Personalized Orders:</b>Custom or personalized artwork may require
                                additional processing time, which can range from an additional [7-14] business days,
                                depending on the complexity of the request. We will communicate any specific timelines
                                during the order confirmation process.</li>
                            <li><b>Business Days:</b>Our business days are [Monday to Friday], excluding UAE public
                                holidays. Orders placed on Fridays or public holidays will be processed on the next
                                business day.</li>
                        </ul>
                    </li>

                    <li>Shipping Destinations
                        <ul>
                            <li><b>Domestic Shipping within the UAE:</b>We offer domestic shipping throughout the UAE.
                                Depending on your location, domestic orders typically arrive within [2-5] business days
                                after being handed over to the courier.</li>
                            <li><b>International Shipping:</b>We ship to selected international destinations across the Middle
                                East, Europe, Asia, and America. Delivery times for international orders vary based on
                                the destination. This will be confirmed during order processing. Some remote locations
                                may require additional time for delivery.</li>
                        </ul>
                    </li>

                    <li>Shipping Methods and Costs
                        <ul>
                            <li><b>Courier Partnerships:</b>We partner with reputable courier services to ensure the safe
                                and timely delivery of your artwork. The choice of courier may depend on the destination
                                and the size or weight of the package.</li>
                            <li><b>Shipping Costs:</b>Shipping costs are calculated during order processing based on the
                                weight, dimensions, and destination of your order. These costs will be communicated to
                                you before you finalize your purchase.
                                <ul>
                                    <li><b>Domestic Shipping Costs:</b>Domestic shipping fees vary depending on the
                                        weight, dimensions, and destination of your order. These costs will be
                                        communicated to you before you finalize your purchase.</li>
                                    <li><b>International Shipping Costs:</b>International shipping fees vary depending on the
                                        destination country. Any additional customs duties, taxes, or import fees are the
                                        responsibility of the customer.</li>
                                </ul>

                            </li>
                            <li><b>Expedited Shipping:</b>We offer expedited shipping options for an additional fee. If you
                                require your order urgently, please confirm while placing an order.</li>
                        </ul>
                    </li>

                    <li>Tracking and Notifications
                        <ul>
                            <li><b>Order Tracking:</b>Once your order is shipped, you will receive a shipping confirmation
                                email containing your tracking number and a link to the courier’s tracking page. This
                                allows you to monitor your package’s journey in real time.</li>
                            <li><b>Delivery Notifications:</b>You will also receive notifications when your order is out for
                                delivery and once it has been successfully delivered. If you do not receive your package
                                within the estimated delivery window, please contact us immediately.</li>
                        </ul>
                    </li>


                    <li>Shipping Times and Delays
                        <ul>
                            <li><b>Estimated Delivery Times:</b>We strive to meet the estimated delivery times provided at
                                order confirmation. However, these are estimates and not guarantees. Delivery times
                                may be affected by factors beyond our control, such as:
                                <ul>
                                    <li><b>Customs Delays:</b>International shipments may be subject to customs
                                        inspections, which could cause delays.</li>
                                    <li><b>Weather Conditions:</b>Severe weather or natural disasters may impact shipping
                                        timelines.</li>
                                    <li><b>Peak Seasons:</b>During peak seasons (e.g., holidays), delivery times may be
                                        longer due to high shipping volumes.</li>
                                    <li><b>Public Holidays:</b>Any public holidays during normal business days may cause a
                                        delay.</li>
                                </ul>
                            </li>

                            <li><b>Notification of Delays:</b>If your order is delayed, we will notify you as soon as possible
                                with an updated delivery estimate. We will work diligently to minimize any disruptions and
                                ensure that your order arrives as soon as possible.</li>
                        </ul>
                    </li>

                    <li>Shipping Insurance and Damaged Items
                        <ul>
                            <li><b>Shipping Insurance:</b>All orders are fully insured against loss or damage during transit.
                                This coverage is included at no additional cost to you.</li>
                            <li><b>Damaged Items:</b>If your artwork arrives damaged, please contact us immediately with
                                your order details and photographs of the damaged item and packaging. We will assess
                                the situation and, if necessary, arrange for a replacement.</li>
                            <li><b>Filing a Claim:</b>To file a claim for a lost or damaged order, please contact our customer
                                service within 3 days of receiving your order ([or within 7 days] of the expected delivery
                                date for lost items). Claims made after this period may not be honored.</li>
                        </ul>
                    </li>

                    <li>Failed Deliveries, Returns, and Re-Shipments
                        <ul>
                            <li><b>Incorrect Address:</b>Please double-check your shipping address before submitting your
                                order. We are not responsible for packages lost or delayed due to incorrect or incomplete
                                addresses provided by the customer.</li>
                            <li><b>Unclaimed Packages:</b>If a package is unclaimed or refused and returned to us by the
                                courier, we will notify you and arrange for re-shipment. Additional shipping fees may apply
                                for re-shipping.</li>
                            <li><b>Re-Shipping Policy:</b>If your package needs to be re-shipped due to a failed delivery
                                attempt or an incorrect address, we will contact you to confirm the correct details.
                                Re-shipment will proceed once additional shipping fees (if applicable) are paid.</li>
                        </ul>
                    </li>

                    <li>Customs, Duties, and Taxes for International Orders
                        <ul>
                            <li><b>Customs Clearance:</b>International orders may be subject to customs clearance
                                procedures, which can cause delays. The time required for customs clearance varies by
                                country and is beyond our control.</li>
                            <li><b>Duties and Taxes:</b>Import duties, taxes, and other customs fees are the responsibility of
                                the customer. These charges are not included in the item price or shipping cost and
                                must be paid upon delivery. Please contact your local customs office for more
                                information on applicable fees.</li>
                            <li><b>Customs Documentation:</b>We will provide all necessary documentation for customs
                                clearance. If additional information or documentation is required by customs authorities,
                                we will assist in any way possible to facilitate the process.</li>
                        </ul>
                    </li>

                    <li>Packaging and Presentation
                        <ul>
                            <li><b>Protective Packaging:</b>We take great care in packaging your artwork to ensure it arrives
                                in perfect condition. Each piece is carefully wrapped in protective materials and placed in
                                sturdy packaging to prevent damage during transit.</li>
                            <li><b>Sustainable Packaging Options:</b>Where possible, we use eco-friendly packaging
                                materials to minimize our environmental impact.</li>
                        </ul>
                    </li>

                    <li>Customer Support and Assistance
                        <ul>
                            <li><b>Contact Us:</b>If you have any questions, concerns, or special requests regarding your
                                order or shipping, please contact our customer support team at
                                info@sketchostorypaintings.com. We are here to assist you every step of the way and
                                ensure your experience with Sketchostory Paintings is exceptional.</li>
                        </ul>
                    </li>

                    <li>Changes to This Shipping Policy
                        <ul>
                            <li><b>Policy Updates:</b>We may update this Shipping Policy periodically to reflect changes in
                                our shipping practices or due to operational, legal, or regulatory requirements. We
                                reserve the right to update or modify this Shipping Policy at any time, for any reason, at our sole discretion. All updates will be posted on this page, and we encourage you to
                                review this policy regularly.</li>
                            <li><b>Effective Date:</b>Changes to this policy are effective immediately upon posting on this
                                page.</li>
                        </ul>
                    </li>
                </ol>
            </div>
            <Footer />
        </>
    )
}

export default ShippingPolicy