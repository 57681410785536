import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./Collections.scss"
import Collections from './Collections';
import i1 from "../../../Images/Collections/8. Islamic Art/1.jpg"
import i2 from "../../../Images/Collections/8. Islamic Art/2.jpg"
import i3 from "../../../Images/Collections/8. Islamic Art/3.jpg"
import i4 from "../../../Images/Collections/8. Islamic Art/4.jpg"
import i5 from "../../../Images/Collections/8. Islamic Art/5.jpg"
import i6 from "../../../Images/Collections/8. Islamic Art/6.jpg"

const IslamicArt = () => {

    const images = [{
        original: i1,
        thumbnail: i1,
    },
    {
        original: i2,
        thumbnail: i2,
    },
    {
        original: i3,
        thumbnail: i3,
    },
    {
        original: i4,
        thumbnail: i4,
    },
    {
        original: i5,
        thumbnail: i5,
    },
    {
        original: i6,
        thumbnail: i6,
    },]

    return (


        <>
            <Collections
                link="/islamic-art"
                linkname="Islamic Art"
                img={images}
                slider_headline="Islamic Art"
                slider_text="Explore the profound beauty and spiritual depth of our Islamic Art collection, where
                            each painting embodies the intricate patterns and rich symbolism of Islamic
                            culture. These artworks showcase the
                            elegance of geometric designs,
                            calligraphy, and architectural marvels,
                            rendered with meticulous detail and
                            harmonious colors."
                p1="Our artists draw upon the rich traditions of Islamic art, creating pieces that not only captivate the eye but also inspire contemplation and reverence. The intricate motifs and serene compositions make each painting a stunning focal point, perfect for enhancing any space."
                p2="Whether displayed in a living room, office, or gallery, these artworks add a touch of spiritual elegance
                        and timeless beauty to your environment."
                p3="Transform your surroundings with the serene majesty of our Islamic Art collection, and let the profound
                        essence of Islamic culture elevate your space."
                p4=""
                url="https://youtu.be/bOwmUZ1YeA0?si=YTEA6314o7OIFt_C"
                videoHeadline="Bringing Imagination to Canvas: A Handmade Painting Process"
                videotext="Take a peek at our collection and how it's brought to life. We have hundreds of pieces for you to
                        choose from. Can’t decide what will look best? We have a team dedicated to help you choose the best pieces for your space."
            />
        </>
    )
}

export default IslamicArt