import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import fb from "../Firebase/firebase";
import { collection, getDocs } from "firebase/firestore";
import { db } from './firebase';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminHeader from './AdminHeader';

const Admin = () => {
    let navigate = useNavigate();
    let id;

    useEffect(() => {

        fb.auth().onAuthStateChanged(function (user) {
            if (user) {
                id = user.uid;
                navigate('/admin');
                console.log("uid", id);
            } else {
                navigate('/admin-login');
                console.log("user is logged out")
            }
        });
        fetchPost();
    }, [])

    const [todos, setTodos] = useState([]);

    const fetchPost = async () => {
        await getDocs(collection(db, "Contact_form"))
            .then((querySnapshot) => {
                const newData = querySnapshot.docs
                    .map((doc) => ({ ...doc.data(), id: doc.id }));
                setTodos(newData);
                console.log(todos, newData);
            })

    }

    const tableRef = useRef(null);
    let i = 1;



    return (
        <>
            <ToastContainer />

            <div className="admin">
                <AdminHeader />
                <div className="responses">
                    <h3>Form Responses of Contact Form</h3>
                    <div className='contact-form'>
                        <DownloadTableExcel
                            filename="Contact Form"
                            sheet="Responses"
                            currentTableRef={tableRef.current}
                        >

                            <button className='export'> Export excel </button>

                        </DownloadTableExcel>
                        <table ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>S. No.</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            {
                                todos?.map((todo, i) => (
                                    <tbody key={i}>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td data-column="Name">{todo.Name}</td>
                                            <td data-column="Email">{todo.Email}</td>
                                            <td data-column="Phone">{todo.Mobile}</td>
                                            <td data-column="Message">{todo.Message}</td>
                                        </tr>
                                    </tbody>
                                ))
                            }
                        </table>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Admin