import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./Collections.scss"
import Collections from './Collections';
import b1 from "../../../Images/Collections/12. Buddha/1.jpg"
import b2 from "../../../Images/Collections/12. Buddha/2.jpg"
import b3 from "../../../Images/Collections/12. Buddha/3.jpg"
import b4 from "../../../Images/Collections/12. Buddha/4.jpg"
import b5 from "../../../Images/Collections/12. Buddha/5.jpg"
import b6 from "../../../Images/Collections/12. Buddha/6.jpg"
import b7 from "../../../Images/Collections/12. Buddha/7.jpg"
import b8 from "../../../Images/Collections/12. Buddha/8.jpg"
import b9 from "../../../Images/Collections/12. Buddha/9.jpg"
import b10 from "../../../Images/Collections/12. Buddha/10.jpg"
import b11 from "../../../Images/Collections/12. Buddha/11.jpg"
import b12 from "../../../Images/Collections/12. Buddha/12.jpg"
import b13 from "../../../Images/Collections/12. Buddha/13.jpg"
import ImageGallery from "react-image-gallery";

const GautamBuddha = () => {
    const images = [
        {
            original: b1,
            thumbnail: b1,
        },
        {
            original: b2,
            thumbnail: b2,
        },
        {
            original: b3,
            thumbnail: b3,
        },
        {
            original: b4,
            thumbnail: b4,
        },
        {
            original: b5,
            thumbnail: b5,
        },
        {
            original: b6,
            thumbnail: b6,
        },
        {
            original: b7,
            thumbnail: b7,
        },
        {
            original: b8,
            thumbnail: b8,
        },
        {
            original: b9,
            thumbnail: b9,
        },
        {
            original: b10,
            thumbnail: b10,
        },
        {
            original: b11,
            thumbnail: b11,
        },
        {
            original: b12,
            thumbnail: b12,
        },
        {
            original: b13,
            thumbnail: b13,
        }
    ]
    return (
        <>
          {/* <ImageGallery items={images} showThumbnails={true} thumbnailPosition={"right"}/> */}
            <Collections
                link="/gautam-buddha"
                linkname="Gautam Buddha"
                img={images}
                slider_headline="Gautam Buddha"
                slider_text="The Gautam Buddha collection is a
                            tribute to the spiritual wisdom and
                            serenity of Buddha, featuring paintings
                            that capture his peaceful presence
                            and teachings, where each painting
                            captures the essence of Buddha's
                            teachings and peaceful presence."
                p1="Each piece in this collection offers a moment of tranquility and introspection, making it an ideal choice
                        for those seeking to bring a sense of inner peace and enlightenment to their surroundings. These
                        artworks reflect the timeless serenity and enlightened grace of Gautam Buddha, brought to life
                        through exquisite detail and calming hues."
                p2="Our artists draw inspiration from the rich spiritual heritage of Buddhism, creating pieces that resonate with tranquility and inner peace. The serene expressions and meditative poses in each painting make
                        them a soothing addition to any setting, whether it be a home, office, or gallery."
                p3="Celebrate the profound wisdom and serene beauty of Gautam Buddha and elevate your space with the
                        calming presence of our Gautam Buddha collection, where every piece invites you to explore the path
                        of enlightenment and inner peace."
                p4=""
                url="https://youtu.be/wgUfovYnnXk?si=uCU6Dl8YhO8tc6v6"
                videoHeadline="The Magic of Handmade Art: An Artist’s Perspective"
                videotext="Venture into our expansive collection and discover its extensive offerings. With hundreds of options
                        available, finding the perfect piece is a breeze. Unsure where to start? Our dedicated team is here to
                        offer expert assistance in selecting the ideal pieces for your space."
            />
        </>
    )
}

export default GautamBuddha