import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./Collections.scss"
import Collections from './Collections';
import f1 from "../../../Images/Collections/11. Flowers/1.jpg"
import f2 from "../../../Images/Collections/11. Flowers/2.jpg"
import f3 from "../../../Images/Collections/11. Flowers/3.jpg"
import f4 from "../../../Images/Collections/11. Flowers/4.jpg"
import f5 from "../../../Images/Collections/11. Flowers/5.jpg"
import f6 from "../../../Images/Collections/11. Flowers/6.jpg"
import f7 from "../../../Images/Collections/11. Flowers/7.jpg"
import f8 from "../../../Images/Collections/11. Flowers/8.jpg"
import f9 from "../../../Images/Collections/11. Flowers/9.jpg"
import f10 from "../../../Images/Collections/11. Flowers/10.jpg"
import f11 from "../../../Images/Collections/11. Flowers/11.jpg"
import f12 from "../../../Images/Collections/11. Flowers/12.jpg"
import f13 from "../../../Images/Collections/11. Flowers/13.jpg"
import f14 from "../../../Images/Collections/11. Flowers/14.jpg"
import f15 from "../../../Images/Collections/11. Flowers/15.jpg"
import f16 from "../../../Images/Collections/11. Flowers/16.jpg"
import f17 from "../../../Images/Collections/11. Flowers/17.jpg"
import f18 from "../../../Images/Collections/11. Flowers/18.jpg"

const Flowers = () => {
    const images = [
        {
            original: f1,
            thumbnail: f1,
        },
        {
            original: f2,
            thumbnail: f2,
        },
        {
            original: f3,
            thumbnail: f3,
        },
        {
            original: f4,
            thumbnail: f4,
        },
        {
            original: f5,
            thumbnail: f5,
        },
        {
            original: f6,
            thumbnail: f6,
        },
        {
            original: f7,
            thumbnail: f7,
        },
        {
            original: f8,
            thumbnail: f8,
        },
        {
            original: f9,
            thumbnail: f9,
        },
        {
            original: f10,
            thumbnail: f10,
        },
        {
            original: f11,
            thumbnail: f11,
        },
        {
            original: f12,
            thumbnail: f12,
        },
        {
            original: f13,
            thumbnail: f13,
        },
        {
            original: f14,
            thumbnail: f14,
        },
        {
            original: f15,
            thumbnail: f15,
        },
        {
            original: f16,
            thumbnail: f16,
        },
        {
            original: f17,
            thumbnail: f17,
        },
        {
            original: f18,
            thumbnail: f18,
        },
    ]

    return (
        <>
            <Collections
                link="/flowers"
                linkname="Flowers"
                img={images}
                slider_headline="Flowers"
                slider_text="The Flowers collection is a celebration
                            of nature's most delicate creations. Each piece is a testament to the
                            elegance and charm of flowers, making
                            it a perfect addition for those who wish to add a touch of natural
                            splendor to their surroundings."
                p1="Experience the delicate beauty and vibrant charm of our Flowers collection, where each painting
                        captures the ephemeral essence of nature's blooms. These artworks showcase a wide variety of
                        flowers, from classic roses to exotic orchids, rendered with stunning detail and luminous colors."
                p2="Our artists bring the elegance and fragility of flowers to life, creating pieces that exude tranquillity and
                        grace. The intricate petals and lush compositions make each painting a serene focal point, perfect for brightening any space."
                p3="Whether displayed in a living room, office, or gallery, these artworks add a touch of natural beauty and
                        timeless elegance to your environment."
                p4="Transform your surroundings with the enchanting allure of our Flowers collection, and let the splendor of nature's blooms elevate your space."
                url="https://youtu.be/bOwmUZ1YeA0?si=YTEA6314o7OIFt_C"
                videoHeadline="Behind the Brush: The Story of a Handmade Painting"
                videotext="Step into our diverse collection and behold its wide-ranging selection. With thousands of pieces to
choose from, finding the perfect fit is effortless. Need assistance in making your selection? Our
knowledgeable team is here to provide personalized guidance and help you curate the perfect"
            />
        </>
    )
}

export default Flowers