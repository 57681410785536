import React from 'react'
import "./Collections.scss"
import Collections from './Collections';
import al1 from "../../../Images/Collections/4. Al Arab/1.jpg"
import al2 from "../../../Images/Collections/4. Al Arab/2.jpg"
import al3 from "../../../Images/Collections/4. Al Arab/3.jpg"
import al4 from "../../../Images/Collections/4. Al Arab/4.jpg"
import al5 from "../../../Images/Collections/4. Al Arab/5.jpg"
import al6 from "../../../Images/Collections/4. Al Arab/6.jpg"
import al7 from "../../../Images/Collections/4. Al Arab/7.jpg"
import al8 from "../../../Images/Collections/4. Al Arab/8.jpg"
import al9 from "../../../Images/Collections/4. Al Arab/9.jpg"
import al10 from "../../../Images/Collections/4. Al Arab/10.jpg"
import al11 from "../../../Images/Collections/4. Al Arab/11.jpg"
import al12 from "../../../Images/Collections/4. Al Arab/12.jpg"
import al13 from "../../../Images/Collections/4. Al Arab/13.jpg"
import al14 from "../../../Images/Collections/4. Al Arab/14.jpg"

const Collection = () => {

    const images = [

        {
            original: al1,
            
           thumbnail: al1,
        },
        {
            original: al2,
           thumbnail: al2,
        },
        {
            original: al3,
           thumbnail: al13,
        },
        {
            original: al4,
           thumbnail: al4,
        },
        {
            original: al5,
           thumbnail: al5,
        },
        {
            original: al6,
           thumbnail: al6,
        },
        {
            original: al7,
           thumbnail: al7,
        },
        {
            original: al8,
           thumbnail: al8,
        },
        {
            original: al9,
           thumbnail: al9,
        },
        {
            original: al10,
           thumbnail: al10,
        },
        {
            original: al11,
           thumbnail: al11,
        },
        {
            original: al12,
           thumbnail: al12,
        },
        {
            original: al13,
           thumbnail: al13,
        },
        {
            original: al14,
           thumbnail: al14,
        },
    ]

    return (
        <>

            <Collections
                link="/al-arab"
                linkname="Al Arab"
                img={images}
                slider_headline="Al Arab"
                slider_text="Step into the enchanting realm of Arab art with Sketchostory
Paintings’ Al Arab collection. Have you
                            ever wondered about life in ancient Arab
                            villages or the foundations of their opulent marketplaces? Look no further. Our
                            collection offers a stunning fusion of classic
                            and contemporary art that will enrich your
                            space with a unique cultural flair."
                p1="Our gifted artists delve deep into the intricate tapestry of Arab culture and history, employing meticulous research and innovative conceptual techniques. They craft precise, dramatic depictions of
                        the intertwined tribes and regal dynasties of the past."
                p2="These exquisite paintings are designed to infuse your room with a sense of majesty and prestige.
                        Transform your walls into a canvas of glory with these remarkable artworks, and experience a
                        reinvention of artistic expression."
                p3=""
                p4=""
                url="https://youtu.be/wgUfovYnnXk?si=uCU6Dl8YhO8tc6v6"
                videoHeadline="Watch This Painting Come Alive: A Step-by-Step Creation"
                videotext="Explore our extensive collection and experience its vast diversity. With thousands of options
                        available, finding the perfect piece is simple. Unsure where to begin? Our dedicated team is here to
                        offer expert advice and assist you in selecting the ideal pieces for your space."
            />
        </>
    )
}

export default Collection