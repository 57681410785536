import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./Collections.scss"
import Collections from './Collections';
import m1 from "../../../Images/Collections/5. Middle Eastern/1.jpg"
import m2 from "../../../Images/Collections/5. Middle Eastern/2.jpg"
import m3 from "../../../Images/Collections/5. Middle Eastern/3.jpg"
import m4 from "../../../Images/Collections/5. Middle Eastern/4.jpg"
import m5 from "../../../Images/Collections/5. Middle Eastern/5.jpg"
import m6 from "../../../Images/Collections/5. Middle Eastern/6.jpg"
import m7 from "../../../Images/Collections/5. Middle Eastern/7.jpg"
import m8 from "../../../Images/Collections/5. Middle Eastern/8.jpg"
import m9 from "../../../Images/Collections/5. Middle Eastern/9.jpg"
import m10 from "../../../Images/Collections/5. Middle Eastern/10.jpg"
import m11 from "../../../Images/Collections/5. Middle Eastern/11.jpg"
import m12 from "../../../Images/Collections/5. Middle Eastern/12.jpg"
import m13 from "../../../Images/Collections/5. Middle Eastern/13.jpg"
import m14 from "../../../Images/Collections/5. Middle Eastern/14.jpg"
import m15 from "../../../Images/Collections/5. Middle Eastern/15.jpg"
import m16 from "../../../Images/Collections/5. Middle Eastern/16.jpg"
import m17 from "../../../Images/Collections/5. Middle Eastern/17.jpg"
import m18 from "../../../Images/Collections/5. Middle Eastern/18.jpg"
import m19 from "../../../Images/Collections/5. Middle Eastern/19.jpg"
import m20 from "../../../Images/Collections/5. Middle Eastern/20.jpg"
import m21 from "../../../Images/Collections/5. Middle Eastern/21.jpg"
import m22 from "../../../Images/Collections/5. Middle Eastern/22.jpg"
import m23 from "../../../Images/Collections/5. Middle Eastern/23.jpg"
import m24 from "../../../Images/Collections/5. Middle Eastern/24.jpg"
import m25 from "../../../Images/Collections/5. Middle Eastern/25.jpg"
import m26 from "../../../Images/Collections/5. Middle Eastern/26.jpg"

const MiddleEastern = () => {

        const images = [
                {
                        original: m1,
                        thumbnail: m1,
                    },
                    {
                        original: m2,
                        thumbnail: m2,
                    },
                    {
                        original: m3,
                        thumbnail: m3,
                    },
                    {
                        original: m4,
                        thumbnail: m4,
                    },
                    {
                        original: m5,
                        thumbnail: m5,
                    },
                    {
                        original: m6,
                        thumbnail: m6,
                    },
                    {
                        original: m7,
                        thumbnail: m7,
                    },
                    {
                        original: m8,
                        thumbnail: m8,
                    },
                    {
                        original: m9,
                        thumbnail: m9,
                    },
                    {
                        original: m10,
                        thumbnail: m10,
                    },
                    {
                        original: m11,
                        thumbnail: m11,
                    },
                    {
                        original: m12,
                        thumbnail: m12,
                    },
                    {
                        original: m13,
                        thumbnail: m13,
                    },
                    {
                        original: m14,
                        thumbnail: m14,
                    },
                    {
                        original: m15,
                        thumbnail: m15,
                    },
                    {
                        original: m16,
                        thumbnail: m16,
                    },
                    {
                        original: m17,
                        thumbnail: m17,
                    },
                    {
                        original: m18,
                        thumbnail: m18,
                    },
                    {
                        original: m19,
                        thumbnail: m19,
                    },
                    {
                        original: m20,
                        thumbnail: m20,
                    },
                    {
                        original: m21,
                        thumbnail: m21,
                    },
                    {
                        original: m22,
                        thumbnail: m22,
                    },
                    {
                        original: m23,
                        thumbnail: m23,
                    },
                    {
                        original: m24,
                        thumbnail: m24,
                    },
                    {
                        original: m25,
                        thumbnail: m25,
                    },
                    {
                        original: m26,
                        thumbnail: m26,
                    },
        ]

        return (
                <>
                        <Collections
                                link="/middle-eastern"
                                linkname="Middle Eastern"
                                img={images}
                                slider_headline="Famous Middle Eastern Artworks"
                                slider_text="Each painting tells a story of opulence
                            and majesty, inviting you to explore the
                            rich tapestry of Middle Eastern culture
                            and history through a modern artistic
                            lens. In addition to their aesthetic
                            appeal, these artworks serve as a bridge between the past and present, weaving historical narratives with
                            contemporary artistic expression."
                                p1="The intricate details and vibrant colors reflect the rich heritage and cultural diversity of the Middle
                        East, making each piece a conversation starter and a source of inspiration. Whether displayed in a living
                        room, office, or gallery, Sketchostory
Paintings' Middle Eastern artwork collection not only enhances the visual
                        appeal of your space but also enriches it with profound cultural significance and timeless elegance."
                                p2="Immerse yourself in the captivating grandeur of Sketchostory
Paintings' exclusive collection of Middle Eastern
                        artwork, masterfully created using mixed media, oil, and acrylic paints. These paintings embody power, wealth, and glory, providing an extraordinary experience that transcends conventional art."
                                p3="Each piece exudes a sense of empowerment, sparking your imagination and elevating your space.
                        Transform your environment with these mesmerizing masterpieces, and let the regal essence of these
                        artworks elevate your surroundings to new heights."
                                p4=""
                                url="https://youtu.be/3b_dpIizcLY?si=QGgqUhQ4QzSxjDFO"
                                videoHeadline="Crafting a Masterpiece: Behind the Scenes of a Handmade Painting"
                                videotext="Embark on a journey through our extensive collection and witness its vast array. With thousands of pieces to explore, finding the perfect match is a breeze. Need assistance in making your selection? Our expert team is here to provide personalized guidance and help you curate the perfect pieces for
                        your space."
                        />
                </>
        )
}

export default MiddleEastern