// components =============================
import React, { useEffect } from 'react'
import Header from "../../Molecules/Header/Header"
import Footer from "../../Molecules/Footer/Footer"
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link } from "react-router-dom"
import Whatsapp from "../../Molecules/Whatsapp/Whatsapp"

// components ends ========================

// scss =============================
import "./Products.scss"
// scss ends ========================

// images =============================
import s1 from "../../Images/Frames/2. Stretched/1.jpg"
import s2 from "../../Images/Frames/2. Stretched/2.jpg"
import s3 from "../../Images/Frames/2. Stretched/3.jpg"
import s4 from "../../Images/Frames/2. Stretched/4.jpg"
import s5 from "../../Images/Frames/2. Stretched/5.jpg"
import s6 from "../../Images/Frames/2. Stretched/6.jpg"
import b1 from "../../Images/Frames/3. Baroque Frames/017.png"
import b2 from "../../Images/Frames/3. Baroque Frames/04.png"
import b3 from "../../Images/Frames/3. Baroque Frames/047-2.png"
import b4 from "../../Images/Frames/3. Baroque Frames/066-3.png"
import b5 from "../../Images/Frames/3. Baroque Frames/071-2.png"
import b6 from "../../Images/Frames/3. Baroque Frames/074-2.png"
import b7 from "../../Images/Frames/3. Baroque Frames/095-3.png"
import b8 from "../../Images/Frames/3. Baroque Frames/099-2.png"
import b9 from "../../Images/Frames/3. Baroque Frames/120.png"
import b10 from "../../Images/Frames/3. Baroque Frames/133.png"
import m1 from "../../Images/Frames/4. Imperial Metal Frames/01.png"
import m2 from "../../Images/Frames/4. Imperial Metal Frames/015.png"
import m3 from "../../Images/Frames/4. Imperial Metal Frames/019-2.png"
import m4 from "../../Images/Frames/4. Imperial Metal Frames/023-2.png"
import m5 from "../../Images/Frames/4. Imperial Metal Frames/083-2.png"
import m6 from "../../Images/Frames/4. Imperial Metal Frames/111-low-cost.png"
import m7 from "../../Images/Frames/4. Imperial Metal Frames/131.png"
import m8 from "../../Images/Frames/4. Imperial Metal Frames/139-low-cost.png"
import m9 from "../../Images/Frames/4. Imperial Metal Frames/150-low-cost.png"
import m10 from "../../Images/Frames/4. Imperial Metal Frames/151-low-cost.png"
import r1 from "../../Images/Frames/5. Renaissance Vintage Frames/038-2.png"
import r2 from "../../Images/Frames/5. Renaissance Vintage Frames/04.png"
import r3 from "../../Images/Frames/5. Renaissance Vintage Frames/048.png"
import r4 from "../../Images/Frames/5. Renaissance Vintage Frames/055.png"
import r5 from "../../Images/Frames/5. Renaissance Vintage Frames/056-2.png"
import r6 from "../../Images/Frames/5. Renaissance Vintage Frames/060.png"
import r7 from "../../Images/Frames/5. Renaissance Vintage Frames/074-2.png"
import r8 from "../../Images/Frames/5. Renaissance Vintage Frames/09.png"
import r9 from "../../Images/Frames/5. Renaissance Vintage Frames/105-2.png"
import r10 from "../../Images/Frames/5. Renaissance Vintage Frames/113.png"
import { Carousel } from 'react-responsive-3d-carousel'

import Title from '../../Molecules/Title/Title';
// images ends ========================

const Frames = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    // stretched =====================
    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };

    const stretched = [
        <div className="frame3-slider-card" data-value="1">
            <img src={s1} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="2">
            <img src={s2} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="3">
            <img src={s3} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="4">
            <img src={s4} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={s5} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="6">
            <img src={s6} alt="1" />
        </div>
    ];

    const baroque = [
        <div className="frame3-slider-card" data-value="1">
            <img src={b1} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="2">
            <img src={b2} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="3">
            <img src={b3} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="4">
            <img src={b4} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={b5} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="6">
            <img src={b6} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={b7} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={b8} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={b9} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={b10} alt="1" />
        </div>,
    ];

    const metal = [
        <div className="frame3-slider-card" data-value="1">
            <img src={m1} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="2">
            <img src={m2} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="3">
            <img src={m3} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="4">
            <img src={m4} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={m5} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="6">
            <img src={m6} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={m7} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={m8} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={m9} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={m10} alt="1" />
        </div>,
    ];

    const renaissance = [
        <div className="frame3-slider-card" data-value="1">
            <img src={r1} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="2">
            <img src={r2} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="3">
            <img src={r3} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="4">
            <img src={r4} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={r5} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="6">
            <img src={r6} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={r7} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={r8} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={r9} alt="1" />
        </div>,
        <div className="frame3-slider-card" data-value="5">
            <img src={r10} alt="1" />
        </div>,
    ];
    // stretched ends===========================


    return (
        <>
            <Header />
<Whatsapp/>
            <div className="frames">
                <Title title="Stretched Canvas Wrap" id="stretched-canvas-wrap" />

                <div className='frames-body'>
                    <div className="stretched-slider">
                        {/* <AliceCarousel
                            mouseTracking
                            items={stretched}
                            responsive={responsive}
                            controlsStrategy="alternate"
                            autoPlay={true}
                            infinite={true}
                            autoPlayInterval={2000}
                        /> */}

                        <Carousel autoPlay={false} interval={1000} showStatus={false} arrowsDefaultColor='#342C27' showIndicators={false}>
                            <img src={s1} alt="example-image-1" />
                            <img src={s2} alt="example-image-2" />
                            <img src={s3} alt="example-image-2" />
                            <img src={s4} alt="example-image-2" />
                            <img src={s5} alt="example-image-2" />
                            <img src={s6} alt="example-image-2" />
                        </Carousel>
                    </div>

                    <div className="stretched-content">
                        <p>We create your paintings on premium-quality canvas available in glossy, matte, or satin finishes. Each piece is hand-stretched to the highest standards, ensuring a drum-tight finish with museum-grade
                            corners. Our canvas wrap edges can be as thick as 4.5 cm, with additional options down to 2 cm. You can
                            choose the edge color, whether a classic white or a custom shade to match your preference.</p>

                        <ul>
                            <li>Hand-stretched to high standards</li>
                            <li>Premium quality canvas</li>
                            <li>Stretched by professionals</li>
                            <li>Painting arrives ready to hang</li>
                        </ul>

                        <p>For Custom sizes, message us on our contact page.</p>
                    </div>
                </div>

                <Title title="Baroque Wooden Luxury" id="baroque-wooden-luxury"/>
                <div className='frames-body'  >
                    <div className="stretched-slider slider2">

                        <div />
                        <Carousel autoPlay={false} interval={1000} showStatus={false} arrowsDefaultColor='#342C27' showIndicators={false}>
                            <img src={b1} alt="example-image-1" />
                            <img src={b2} alt="example-image-2" />
                            <img src={b3} alt="example-image-2" />
                            <img src={b4} alt="example-image-2" />
                            <img src={b5} alt="example-image-2" />
                            <img src={b6} alt="example-image-2" />
                            <img src={b7} alt="example-image-2" />
                            <img src={b8} alt="example-image-2" />
                            <img src={b9} alt="example-image-2" />
                            <img src={b10} alt="example-image-2" />
                        </Carousel>

                        {/* <AliceCarousel
                            mouseTracking
                            items={baroque}
                            responsive={responsive}
                            controlsStrategy="alternate"
                            autoPlay={true}
                            infinite={true}
                            autoPlayInterval={2000}
                        /> */}
                    </div>

                    <div className="stretched-content">
                        <p>Treasure your paintings for a lifetime with our beautiful solid wood picture frames. Each wooden frame is unique, offering a vast range of painted or stained finishes to suit any style, whether classic or traditional. Our frames not only enhance the aesthetic appeal of your artwork with natural beauty but also provide durable and long-lasting protection.</p>
                        <p>They can match any décor, adding timeless elegance and professional presentation to elevate the value of your painting. They are guaranteed to protect and bring your painting to life.</p>

                        <ul>
                            <li>Timeless wooden frames in black, white and natural</li>
                            <li>Premium quality to protect your paintings</li>
                            <li>Framed by professionals</li>
                            <li>Standard formats or special sizes available for all kinds of paintings</li>
                            <li>Painting arrives ready to hang</li>
                        </ul>

                        <p>For Custom sizes, message us on our contact page.</p>
                    </div>
                </div>

                <Title title="Empyreal Metal" id="empyreal-metal"/>
                <div className='frames-body'>
                    <div className="stretched-slider slider2">
                        <Carousel autoPlay={false} interval={1000} showStatus={false} arrowsDefaultColor='#342C27' showIndicators={false}>
                            <img src={m1} alt="example-image-1" />
                            <img src={m2} alt="example-image-2" />
                            <img src={m3} alt="example-image-2" />
                            <img src={m4} alt="example-image-2" />
                            <img src={m5} alt="example-image-2" />
                            <img src={m6} alt="example-image-2" />
                            <img src={m7} alt="example-image-2" />
                            <img src={m8} alt="example-image-2" />
                            <img src={m9} alt="example-image-2" />
                            <img src={m10} alt="example-image-2" />
                        </Carousel>
                        {/* <AliceCarousel
                            mouseTracking
                            items={metal}
                            responsive={responsive}
                            controlsStrategy="alternate"
                            autoPlay={true}
                            infinite={true}
                            autoPlayInterval={2000}
                        /> */}
                    </div>

                    <div  className="stretched-content">
                        <p>Add timeless charm to your artwork with our antique-looking metal frames. Enhance your artwork with
                            these frames that combine vintage elegance with sturdy craftsmanship. Ensuring a blend of the classic and modern style with strong durability. Made from high-quality materials, they offer a unique and
                            sophisticated way to display your paintings.</p>
                        <p>They feature intricate details and classic finishes that evoke a sense of history and nostalgia. Our metal
                            frames boast a traditional look that suits any décor, from minimalist to industrial. Available in different
                            finishes like brushed, polished, and matte, our metal frames can be customized to perfectly showcase
                            your paintings.</p>
                        <p>Metal frames provide long-lasting protection and their clean, stylish design makes your artwork stand out. Metal frames enhance the beauty of your artwork while providing long-lasting protection. Choose these
                            frames to give your treasured pieces a distinctive, elegant touch that captures the essence of a bygone
                            era.</p>

                        <ul>
                            <li>Made from high-quality durable materials for long-lasting use.</li>
                            <li> Features ornate designs and classic finishes that stand out.</li>
                            <li> Complements traditional, eclectic, and vintage décors.</li>
                            <li> Unique appearance sets your artwork apart.</li>
                            <li> Robust support, ensuring your artwork remains secure.</li>
                            <li> Resistant to warping and damage, requiring minimal upkeep.</li>
                            <li> Can be tailored to fit any artwork dimensions.</li>
                            <li>Ideal for galleries, exhibitions, and home displays, adding a touch of sophistication.</li>
                        </ul>

                        <p>For Custom sizes, message us on our contact page.</p>
                    </div>
                </div>

                <Title title="Renaissance Vintage" id="renaissance-vintage"/>
                <div className='frames-body'>
                    <div className="stretched-slider slider2">
                        <Carousel autoPlay={false} interval={1000} showStatus={false} arrowsDefaultColor='#342C27' showIndicators={false} >
                            <img src={r1} alt="example-image-1" />
                            <img src={r2} alt="example-image-2" />
                            <img src={r3} alt="example-image-2" />
                            <img src={r4} alt="example-image-2" />
                            <img src={r5} alt="example-image-2" />
                            <img src={r6} alt="example-image-2" />
                            <img src={r7} alt="example-image-2" />
                            <img src={r8} alt="example-image-2" />
                            <img src={r9} alt="example-image-2" />
                            <img src={r10} alt="example-image-2" />
                        </Carousel>
                        {/* <AliceCarousel
                            mouseTracking
                            items={renaissance}
                            responsive={responsive}
                            controlsStrategy="alternate"
                            autoPlay={true}
                            infinite={true}
                            autoPlayInterval={2000}
                        /> */}
                    </div>

                    <div className="stretched-content">
                        <p>Discover the perfect blend of durability and versatility with our fiber framing options. Lightweight yet
                            sturdy, fiber frames offer a modern and affordable alternative to traditional wood and metal frames. Available in a range of colors and finishes, these frames can easily match any décor, making them an ideal</p>
                        <p>Their resilience ensures long-lasting protection for your artwork, while their sleek design adds a
                            contemporary touch. Choose our fiber frames for a stylish, practical, and budget-friendly solution to display your cherished pieces.</p>

                        <ul>
                            <li>Enhances the appeal of your artwork. </li>
                            <li>Cost-effective framing solution without compromising quality.</li>
                            <li> Resilient materials ensure your artwork is well-protected over time.</li>
                            <li> Lightweight nature makes it easy to hang and move. </li>
                            <li>Resistant to warping and damage, requiring minimal upkeep. </li>
                            <li>Perfect for any space, from living rooms to offices, adding a stylish touch.</li>
                        </ul>

                        <p>For Custom sizes, message us on our contact page.</p>
                    </div>
                </div>

                <div className="last">
                    <h4>Need additional help? Feel free to reach out!</h4>
                    <Link to={"/contact"}>contact us</Link>
                </div>


            </div>
            <div className="frame9">
                <p>Rate us on Trust Pilot / Google</p>
                <div className="frame9-star">
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.467,23.316,12,17.828,4.533,23.316,7.4,14.453-.063,9H9.151L12,.122,14.849,9h9.213L16.6,14.453Z" /></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.467,23.316,12,17.828,4.533,23.316,7.4,14.453-.063,9H9.151L12,.122,14.849,9h9.213L16.6,14.453Z" /></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.467,23.316,12,17.828,4.533,23.316,7.4,14.453-.063,9H9.151L12,.122,14.849,9h9.213L16.6,14.453Z" /></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M19.467,23.316,12,17.828,4.533,23.316,7.4,14.453-.063,9H9.151L12,.122,14.849,9h9.213L16.6,14.453Z" /></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        <path d="m24.043,8.992h-9.214L11.981.114l-2.849,8.878H-.082l7.46,5.453-2.864,8.863,7.467-5.488,7.467,5.488-2.864-8.863,7.46-5.453Zm-12.062,6.346V6.661l1.39,4.331h4.548l-3.683,2.691,1.403,4.344-3.658-2.689Z" />
                    </svg>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default Frames