import React, {useEffect} from 'react'
import Header from '../../Molecules/Header/Header'
import Footer from '../../Molecules/Footer/Footer'
import Whatsapp from '../../Molecules/Whatsapp/Whatsapp'

const PrivacyPolicy = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);
    
    return (
        <>
            <Header />
            <Whatsapp />
            <div className='policy'>
                <h2>Privacy Policy for Sketchostory Paintings</h2>
                <p>This Privacy Policy describes how Sketchostory Paintings (“we,” “our,” or “us”) collects, uses,
                    and discloses your Personal Information when you use our services (“Service”). We are
                    committed to protecting your privacy and ensuring that your Personal Information is handled in a
                    safe and responsible manner.</p>

                <h3>Collection and Use of Personal Information</h3>
                <p>We may collect certain personally identifiable information (“Personal Information”) to provide and
                    improve our Service. This information may include, but is not limited to:</p>
                <ul>
                    <li>Name</li>
                    <li>Email Address</li>
                    <li>Telephone Number</li>
                    <li>Address</li>
                </ul>
                <p>Your Personal Information is used solely to provide and enhance our Service. By using our
                    Service, you agree to the collection and use of this information in line with this Privacy Policy.</p>

                <h3>Log Data</h3>
                <p>When you visit our Service, we automatically collect information that your browser sends (“Log
                    Data”). This may include details such as your computer’s Internet Protocol (“IP”) address,
                    browser type, version, the pages you visit, the time and date of your visit, time spent on those
                    pages, and other statistics.</p>

                <h3>Cookies</h3>
                <p>We use cookies to improve your experience on our website. Cookies are small files stored on
                    your device that help us understand your preferences and usage patterns. You may choose to
                    disable cookies through your browser settings; however, please note that doing so may limit your
                    ability to use certain features or access specific pages of our Service.</p>

                <h3>Use of Information for Marketing</h3>
                <p>We may use your Personal Information to contact you with newsletters, promotional materials,
                    or other marketing communications that may be of interest to you. If you do not wish to receive
                    these communications, you may opt-out by following the unsubscribe instructions provided in the
                    communication or by contacting us directly.</p>

                <h3>Service Providers</h3>
                <p>We may engage third-party companies to assist with our Service. These service providers have
                    access to your Personal Information only to perform specific tasks on our behalf and are
                    required to protect your information in line with this Privacy Policy.</p>

                <h3>Security</h3>
                <p>We take the security of your Personal Information seriously. While we implement various
                    security measures to protect your data, please understand that no method of transmission over
                    the Internet or electronic storage is completely secure. We strive to use commercially
                    acceptable means to safeguard your Personal Information but cannot guarantee its absolute
                    security.</p>

                <h3>Links to Third-Party Sites</h3>
                <p>Our Service may contain links to external websites that are not operated by us. We are not
                    responsible for the privacy practices or content of these third-party sites and encourage you to
                    review their Privacy Policies.</p>

                <h3>Children’s Privacy</h3>
                <p>Our Service is not intended for individuals under the age of 18. We do not knowingly collect
                    Personal Information from children under 18. If we become aware that we have inadvertently
                    collected such information, we will take steps to delete it from our records immediately.</p>

                <h3>Legal Compliance</h3>
                <p>We will disclose your Personal Information if required by law, legal process, or subpoena.</p>

                <h3>Changes to This Privacy Policy</h3>
                <p>We may update this Privacy Policy periodically. Any changes will be posted on this page, and we
                    encourage you to review this policy regularly. Changes are effective immediately upon posting to
                    this page.</p>

                <h3>Contact Us</h3>
                <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:info@sketchostorypaintings.com"> info@sketchostorypaintings.com</a></p>
            </div>
            <Footer />
        </>
    )
}

export default PrivacyPolicy