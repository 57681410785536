import React, {useEffect} from 'react'
import Header from '../../Molecules/Header/Header'
import Footer from '../../Molecules/Footer/Footer'
import Whatsapp from '../../Molecules/Whatsapp/Whatsapp'
import "./Policies.scss"

const TermsOfService = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    return (
        <>
            <Header />
            <Whatsapp />
            <div className='policy'>
                <h2>Terms of Service for Sketchostory Paintings</h2>
                <p>Welcome to Sketchostory Paintings! By accessing or using our website and services, you agree
                    to comply with and be bound by the following Terms of Service. Please read these terms
                    carefully before using our site. If you do not agree to these terms, please do not use our
                    services.</p>
                <ol>
                    <li>Acceptance of Terms</li>
                    <p>By using our website and services, you agree to these Terms of Service and our Privacy Policy.
                        We reserve the right to modify these terms at any time, and your continued use of our services
                        constitutes acceptance of any changes.</p>
                    <li>Eligibility</li>
                    <p>You must be at least 18 years old to use our services. By using our website, you represent that
                        you are of legal age and have the capacity to enter into a legally binding agreement.</p>
                    <li>Contact Information
                        <ul>
                            <li><b>Customer Interaction:</b> Customers can provide their contact details on our website or
                                choose to contact us via phone or WhatsApp. Your information will be used to process
                                orders and communicate with you.</li>
                        </ul>
                    </li>

                    <li>Product Information
                        <ul>
                            <li><b>Accuracy:</b>We strive to provide accurate descriptions and images of our products.
                                However, we do not guarantee that the descriptions, images, or other content on our
                                website are accurate, complete, or current. Please note that there may be minor
                                differences between the picture and the final product due to the handmade nature of our
                                paintings made with mixed media.</li>
                            <li><b>Product Description:</b>While a significant portion
                                of the production process is done by hand, some automation is used.</li>
                            <li><b>Availability:</b>All products are subject to availability. We reserve the right to limit quantities
                                and to discontinue products without notice.</li>
                        </ul>
                    </li>

                    <li>
                        Order Processing
                        <ul>
                            <li><b>Acceptance:</b>Your order is not considered accepted until we confirm it and payment is
                                processed. We reserve the right to refuse or cancel any order at our discretion.</li>
                            <li><b>Pricing:</b>Prices are subject to change without notice. We are not liable for any errors in
                                pricing or product information.</li>
                        </ul>


                    </li>

                    <li>
                        Payment
                        <ul>
                            <li><b>Methods:</b>We accept various payment methods, which will be confirmed during the
                                order processing. You agree to provide accurate payment information and authorize us to
                                charge your payment method for the amount agreed for your order.</li>
                            <li><b>Security:</b>We use secure payment processing methods to protect your financial
                                information. However, we are not responsible for any errors or issues that arise with
                                payment processing.</li>
                        </ul>
                    </li>

                    <li>
                        Shipping and Delivery
                        <ul>
                            <li><b>Shipping:</b>We offer shipping services as outlined in our Shipping Policy. Delivery times
                                are estimates and may vary based on factors such as location and shipping method.</li>
                            <li><b>Risk of Loss:</b>Risk of loss and title to products pass to you upon delivery to the carrier.
                                We are not responsible for any loss, damage, or delay once the product is in transit.</li>
                        </ul>
                    </li>

                    <li>Returns and Replacements
                        <ul>
                            <li><b>Policy:</b>Our Returns Policy governs the process for returning items and requesting
                                replacements. Please review this policy for details on eligibility, procedures, and
                                responsibilities.</li>
                        </ul>
                    </li>

                    <li>Intellectual Property
                        <ul>
                            <li><b>Ownership:</b>All content, trademarks, and other intellectual property on our website are
                                owned by Sketchostory Paintings or its licensors. You may not use, reproduce, or
                                distribute any content without our prior written consent.</li>
                        </ul>
                    </li>

                    <li>User Conduct
                        <ul>
                            <li><b>Prohibited Activities:</b>You agree not to use our website or services for any unlawful
                                purpose or in any manner that could damage, disable, overburden, or impair our website.
                                You may not engage in any activity that interferes with or disrupts our services.</li>
                        </ul>
                    </li>

                    <li>Disclaimer of Warranties
                        <ul>
                            <li><b>As-Is Basis:</b>Our website and services are provided on an "as-is" and "as-available"
                                basis without any warranties of any kind, whether express or implied. We do not warrant
                                that our website will be uninterrupted, error-free, or free of viruses or other harmful
                                components.</li>
                        </ul>
                    </li>

                    <li>Limitation of Liability
                        <ul>
                            <li><b>Exclusion of Liability:</b>To the maximum extent permitted by law, Sketchostory Paintings
                                shall not be liable for any indirect, incidental, special, or consequential damages arising
                                out of or in connection with your use of our website or services.</li>
                        </ul>
                    </li>

                    <li>Indemnification
                        <ul>
                            <li><b>Responsibility:</b>You agree to indemnify and hold harmless Sketchostory Paintings and
                                its affiliates from any claims, damages, liabilities, and expenses arising out of your use of
                                our website or services or your violation of these Terms of Service.</li>
                        </ul>
                    </li>

                    <li>Governing Law
                        <ul>
                            <li><b>Jurisdiction:</b>These Terms of Service are governed by and construed in accordance
                                with the laws of the United Arab Emirates. Any disputes arising from these terms or your
                                use of our services will be resolved in the courts of the UAE.</li>
                        </ul>
                    </li>

                    <li>Termination
                        <ul>
                            <li><b>Termination of Access:</b>We reserve the right to suspend or terminate your access to
                                our website or services at any time, without notice, for any reason, including but not
                                limited to your breach of these Terms of Service.</li>
                        </ul>
                    </li>

                    <li>Contact Us</li>
                    <p>If you have any questions or concerns regarding these Terms of Service, please contact us at
                        <a href="mailto:info@sketchostorypaintings.com"> info@sketchostorypaintings.com</a> .</p>
                </ol>
            </div>
            <Footer />
        </>
    )
}

export default TermsOfService