import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./Collections.scss"
import Collections from './Collections';
import re1 from "../../../Images/Collections/6. Renaissance/1.jpg"
import re2 from "../../../Images/Collections/6. Renaissance/2.jpg"
import re3 from "../../../Images/Collections/6. Renaissance/3.jpg"
import re4 from "../../../Images/Collections/6. Renaissance/4.jpg"
import re5 from "../../../Images/Collections/6. Renaissance/5.jpg"
import re6 from "../../../Images/Collections/6. Renaissance/6.jpg"
import re7 from "../../../Images/Collections/6. Renaissance/7.jpg"
import re8 from "../../../Images/Collections/6. Renaissance/8.jpg"
import re9 from "../../../Images/Collections/6. Renaissance/9.jpg"
import re10 from "../../../Images/Collections/6. Renaissance/10.jpg"
import re11 from "../../../Images/Collections/6. Renaissance/11.jpg"
import re12 from "../../../Images/Collections/6. Renaissance/12.jpg"
import re13 from "../../../Images/Collections/6. Renaissance/13.jpg"
import re14 from "../../../Images/Collections/6. Renaissance/14.jpg"
import re15 from "../../../Images/Collections/6. Renaissance/15.jpg"
import re16 from "../../../Images/Collections/6. Renaissance/16.jpg"
import re17 from "../../../Images/Collections/6. Renaissance/17.jpg"

const Renaissance = () => {

    const images = [
        {
            original: re1,
            thumbnail: re1,
        },
        {
            original: re2,
            thumbnail: re2,
        },
        {
            original: re3,
            thumbnail: re3,
        },
        {
            original: re4,
            thumbnail: re4,
        },
        {
            original: re5,
            thumbnail: re5,
        },
        {
            original: re6,
            thumbnail: re6,
        },
        {
            original: re7,
            thumbnail: re7,
        },

        {
            original: re8,
            thumbnail: re8,
        },
        {
            original: re9,
            thumbnail: re9,
        },
        {
            original: re10,
            thumbnail: re10,
        },
        {
            original: re11,
            thumbnail: re11,
        },
        {
            original: re12,
            thumbnail: re12,
        },
        {
            original: re13,
            thumbnail: re13,
        },
        {
            original: re14,
            thumbnail: re14,
        },
        {
            original: re15,
            thumbnail: re15,
        },
        {
            original: re16,
            thumbnail: re16,
        },
        {
            original: re17,
            thumbnail: re17,
        },
    ];


    return (
        <>
            <Collections
                link="/renaissance"
                linkname="Renaissance"
                img={images}
                slider_headline="Renaissance"
                slider_text="Step into the world of artistic revival, where
                            each painting embodies the timeless
                            elegance of the Renaissance period. These
                            artworks capture the essence of this pivotal
                            era, marked by a renewed interest in
                            classical learning, humanism, and artistic mastery, through meticulously crafted
                            details and vibrant palettes."
                p1="Our talented artists draw inspiration from the great masters of the Renaissance, creating pieces that
                        reflect the era's profound impact on art and culture. Our collection is inspired by great artists such as
                        Ludwig Deutsch, Carl Haag, Robert Ernst and many more renaissance artists."
                p2="The intricate compositions and rich color schemes make each painting a captivating focal point, perfect
                        for any setting. Whether displayed in a living room, office, or gallery, these artworks bring the grandeur
                        and sophistication of the Renaissance into your space, inspiring admiration and reflection."
                p3="Transform your surroundings with the refined beauty of our Renaissance collection, and let the spirit of
                        artistic rebirth elevate your environment."
                p4=""
                url="https://youtu.be/bOwmUZ1YeA0?si=YTEA6314o7OIFt_C"
                videoHeadline="The Art of Creation: Watch This Painting Unfold"
                videotext="Explore our vibrant collection and witness its vibrant array. With thousands of pieces at your
                        fingertips, finding the perfect fit is effortless. Need guidance? Our dedicated team is here to assist
                        you in selecting the ideal pieces for your space."
            />
        </>
    )
}

export default Renaissance