import "./Admin.scss"
import logoimg from "../Images/Logo/logo.png"
import logotext from "../Images/Logo/logo text.png"
import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {

    //fetching data from form

    const [userData, setUserData] = useState({
        Email: "",
        Password: ""

    });

    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }

    //fetching data from form ends

    const navigate = useNavigate();

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, userData.Email, userData.Password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                navigate("/admin")
                console.log(user);
                toast.success("logged in Successfully")
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                alert(errorCode, errorMessage);
            });

    }

    return (
        <>
            <ToastContainer />

            <div className="login">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6"></div>

                        <div class="col-lg-6 d-flex align-items-center justify-content-center right-side">
                            <div class="form-2-wrapper">
                                <div class="logo text-center">
                                    <img src={logoimg} alt="logo" className='logoimg' />
                                    <img src={logotext} alt="logo" className='logotext' />
                                </div>
                                <h2 class="text-center mb-4">Login</h2>
                                <form action="#" onSubmit={onLogin}>
                                    <div class="mb-3 form-box">
                                        <input type="email" class="form-control" id="email" name="Email" placeholder="Enter Your Email"
                                            value={userData.Email}
                                            onChange={postUserData}
                                            required />
                                    </div>
                                    <div class="mb-3">
                                        <input type="password" class="form-control" id="password" name="Password" placeholder="Enter Your Password"
                                            value={userData.Password}
                                            onChange={postUserData}
                                            required />
                                    </div>

                                    <button type="submit" class="btn btn-outline-secondary login-btn w-100 mb-3">Login</button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Login