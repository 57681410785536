import React from 'react'
import whatsapp from '../../Images/Homepage/whatsapp icon.png'
import "./Whatsapp.scss"

const Whatsapp = () => {

   
    return (
        <>
            <div className="whatsapp" >
                <a href="https://wa.me/+971504348145/">
                    <img src={whatsapp} alt="whatsapp" />
                </a>
            </div>
        </>
    )
}

export default Whatsapp