import React, { useEffect } from 'react'
import Header from '../../../Molecules/Header/Header'
import Footer from '../../../Molecules/Footer/Footer'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./Collections.scss"
import sizechart from "../../../Images/Customized Paintings/size chart.png"
import ReactPlayer from 'react-player/youtube'
import Collections from './Collections';

import r1 from "../../../Images/Collections/1. Royal Portraits/1.jpg"
import r2 from "../../../Images/Collections/1. Royal Portraits/2.jpg"
import r3 from "../../../Images/Collections/1. Royal Portraits/3.jpg"
import r4 from "../../../Images/Collections/1. Royal Portraits/4.jpg"
import r5 from "../../../Images/Collections/1. Royal Portraits/5.jpg"

const RoyalPortraits = () => {

    const images = [
        {
            original: r1,
            thumbnail: r1,
            id: "royal_portraits"
        },
        {
            original: r2,
            thumbnail: r2,
            id: "royal_portraits"
        },
        {
            original: r3,
            thumbnail: r3,
            id: "royal_portraits"
        },
        {
            original: r4,
            thumbnail: r4,
            id: "royal_portraits"
        },
        {
            original: r5,
            thumbnail: r5,
            id: "royal_portraits"
        },
    ]

    return (
        <>
            <Collections
                link="/royal-portraits"
                linkname="Royal Portraits"
                img={images}
                slider_headline="Royal Portraits"
                slider_text="Experience the majestic charm of the leaders with Sketchostory Paintings Royal Portrait collection.Our curated selection captures the essence of Emirati nobility, adding a touch of regal elegance to any space.We have portraits of all royal members of the royal families."
                p1="Our collections are designed to blend seamlessly into any interior space. Experience the pinnacle of visual excellence as our handmade royal portraits bring the rulers of the UAE to life within the frame. Each portrait is rendered with breathtaking clarity and precision, ensuring a truly immersive viewing experience."
                p2="Chitrvilas royal portraits hold a distinguished reputation within the UAE's governmental and public sectors. We have a proven track record of supplying our prestigious portraits to various establishments, including government entities, hotels, hospitals, and offices."
                p3=""
                p4=""
                videoHeadline="Creating Art: Watch a Masterpiece Come to Life"
                videotext="Explore our collection and experience UAE’s history. With hand-picked, and original options
                        available, finding the perfect piece is simple. Unsure where to begin? Our dedicated team is here to
                        offer expert advice and assist you in selecting the ideal pieces for your space."
                url="https://youtu.be/3b_dpIizcLY?si=QGgqUhQ4QzSxjDFO"
            />
        </>
    )
}

export default RoyalPortraits